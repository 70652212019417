.calendar {
    width: 271px; /*327px*/
    max-height: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.calendar__arrow {
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-top: 3px solid #232360;
    border-right: 3px solid #232360;
    transition: border 0.2s ease-in-out;
}

.calendar__arrow:hover {
    border-top: 3px solid #5932EA;
    border-right: 3px solid #5932EA;
    transition: border 0.2s ease-in-out;
}

.calendar__prevmonth-btn {
    margin-right: 25px;
    transform: rotate(-135deg);
}

.calendar__nexmonth-btn {
    margin-left: 25px;
    transform: rotate(45deg);
}

.calendar__table-container {
    width: 290px; /*245px*/
    max-height: 245px;
    margin-top: 23px;
    overflow: hidden ;
}

.calendar__table-container__header {
    max-width: 224px;
}

.calendar__week-names,
.calendar__month-date {
    width: 35px;
    height: 35px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #232360;
    padding: 0;
    margin: 0;
}

.calendar__select {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.calendar__navigation-current-date,
.calendar__select {
    padding-bottom: 0px;
    font-size: 18px;
    line-height: 25px;
    color: #232360;
}

.past-days {
    color: #C6C7F8;
    pointer-events: none;
}

.calendar__select-month {
    display: block;
    width: 85px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
}

.disabled-month {
    display: none;
}

.disabled-arrow {
    pointer-events: none;
    border-top: 3px solid #C6C7F8;
    border-right: 3px solid #C6C7F8;
    transition: border 0.2s ease-in-out;
}

.calendar__select-year {
    width: 44px;
    text-align: center;
}

.calendar__select-name {
    display: block;
    width: 85px;
    text-align: center;
}

.calendar__select-list,
.calendar__select-list-birthday {
    position: absolute;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px;
    z-index: 999;
}

.calendar__select-list {
    max-height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    /* -ms-overflow-style: none; */
    padding: 0px 0px;
}

.calendar__select-list::-webkit-scrollbar-track {
    background-color: transparent;
    position: absolute;
    margin-top: 8px;
    margin-bottom: 8px;
}

.calendar__select-list::-webkit-scrollbar {
    width: 5px;
}

.calendar__select-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #A4A8B1;
}

.selected-item {
    display: block;
    width: 11px;
    height: 8px;
    background-image: url(../icons/svg/check-priority.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}

.calendar__select-list-birthday {
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 16px 0px;
}

.calendar__select-item {
    display: flex;
    gap: 6px;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
}

.calendar__select-item:hover {
    background-color: #F2EEFF;
    color: #232360;
    cursor: pointer;
}   

.calendar__navigation-current-date {
    max-width: 22px;
    text-align: center;
}

.current-date {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 35px; */
    /* height: 35px; */
    font-weight: 700;
    color: #232360;
}

.current-date:hover {
    color: #FFFFFF;
}



.calendar__month-date {
    cursor: pointer;
}

.calendar__month-date:hover p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    background-color: #5932EA;
}

.calendar__month-date:hover .in-range::after,
.calendar__month-date:hover .in-range::before {
    background-color: transparent;
}

.selected-day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #5932EA;
}

.in-range {
    position: relative;
    background-color: #F2EEFF;
    
}

/* .in-range:hover {
    background-color: transparent;
} */

.in-range::before, .in-range::after {
    content: '';
    display: flex;
    align-items: center;
    width: 10px;
    height: 25px;
    position: absolute;
    z-index: -99;
    background-color: #F2EEFF;
}

.in-range::before {
    top: 0;
    margin-left: -10px;
}

.in-range::after {
    top: 0px;
    right: -10px;
}
.TaskForecast-container,
.SpentTime-container,
.TaskCompleted-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(313.12deg, #5932EA 0.01%, #40C0F8 100.01%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
    border-radius: 16px;
    cursor:default;
}

.first-line__list-item {
    display: flex;
    height: 240px;
    width: 100%;
    max-width: 458px;
}

.TaskForecast-content,
.SpentTime-content,
.TaskCompleted-content,
.SpentTime-loader {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.TaskForecast-data,
.SpentTime-data,
.TaskCompleted-data {
    font-weight: 600;
    line-height: 98px;
    font-size: 72px;
    color: #FFFFFF;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.TaskForecast-description,
.SpentTime-description,
.TaskCompleted-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.8);
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    text-align: center;
}

.TaskForecast-content__info,
.SpentTime-content__info,
.TaskCompleted-content__info {
    display: block;
    position: absolute;
    width: 19.5px;
    height: 19.5px;
    top: 0px;
    right: 0px;
    margin-top: 14px;
    margin-right: 14px;
    mask-image: url(../../../../../icons/svg/TaskStats/TaskStats__info.svg);
    mask-size: 19.5px 19.5px;
    background-color: #FFFFFF80;
    transition: background-color 0.2s ease-in-out;
}

.TaskForecast-content__info-container,
.SpentTime-content__info-container,
.TaskCompleted-content__info-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}

.TaskForecast-content__info-container__description,
.SpentTime-content__info-container__description,
.TaskCompleted-content__info-container__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.moved-info {
    background-color: #FFFFFF;
    transition: background-color 0.1s ease-in-out;
}

.SpentTime-content-data {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;    
}

.SpentTime-data-desc {
    font-size: 20px;
    line-height: 27px;
}

.info-hidden {
    display: none;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }

@keyframes blockIn {
    0% {display: block;}
    100% {display: none;}
}

@media (max-width: 576px) {
    .TaskForecast-container,
    .TaskCompleted-container {
        max-width: 100%;
        height: 100%;
    }
    .SpentTime-container {
        width: 100%;
        height: 100%;
    }
    .TaskForecast-data,
    .SpentTime-data,
    .TaskCompleted-data {
        font-size: 44px;
        line-height: 1.3;
    }
    .TaskForecast-description,
    .SpentTime-description,
    .TaskCompleted-description {
        font-size: 12px;
    }
    .TaskForecast-content__info-container__description,
    .SpentTime-content__info-container__description,
    .TaskCompleted-content__info-container__description {
        line-height: 28px;
    }
}

.clicked-info__mobile {
    display: block;
    position: absolute;
    width: 15.5px;
    height: 15.5px;
    top: 0px;
    right: 0px;
    margin-top: 15px;
    margin-right: 15px;
    stroke: #FFFFFF;
    /* stroke-opacity: 0.5; */
    transition: stroke 0.2s ease-in-out;
}

.clicked-info__mobile:hover {
    stroke: #FFFFFF;
    stroke-opacity: 1;
    transition: stroke 0.2s ease-in-out;
}

@media (max-width: 1600px) and (min-width: 1280px) {
    .first-line__list-item {
        height: 180px;
    }
    .TaskForecast-description,
    .SpentTime-description,
    .TaskCompleted-description {
        font-size: 12px;
    }
    .TaskForecast-content__info-container__description,
    .SpentTime-content__info-container__description,
    .TaskCompleted-content__info-container__description {
        font-size: 14px;
        line-height: 24px;
    }
    .TaskForecast-data,
    .SpentTime-data,
    .TaskCompleted-data {
        font-size: 56px;
    }
}

@media (min-width: 768px) and (max-width: 856px) {
    .TaskForecast-container,
    .SpentTime-container,
    .TaskCompleted-container {
        height: 173px;
    }
    .TaskForecast-description,
    .SpentTime-description,
    .TaskCompleted-description {
        font-size: 12px;
    }
    .TaskForecast-data,
    .SpentTime-data,
    .TaskCompleted-data {
        font-size: 44px;
        line-height: normal;
    }
    .TaskForecast-content__info-container__description,
    .SpentTime-content__info-container__description,
    .TaskCompleted-content__info-container__description {
        font-size: 12px;
    }
    .mobile-br-hidden {
        display: none;
    }
    .TaskForecast-content__info,
    .SpentTime-content__info,
    .TaskCompleted-content__info {
        display: block;
        position: absolute;
        width: 15.5px;
        height: 15.5px;
        top: 0px;
        right: 0px;
        margin-top: 10px;
        margin-right: 10px;
        mask-image: url(../../../../../icons/svg/TaskStats/TaskStats__info.svg);
        mask-size: 15.5px 15.5px;
        background-color: #FFFFFF80;
        transition: background-color 0.2s ease-in-out;
    }
    .moved-info {
        background-color: #FFFFFF;
        transition: background-color 0.1s ease-in-out;
    }
}

@media (min-width: 857px) and (max-width: 1024px) {
    .TaskForecast-container,
    .SpentTime-container,
    .TaskCompleted-container {
        height: 220px;
    }
    .TaskCompleted-data{
        font-size: 56px;
    }
    .TaskForecast-description,
    .SpentTime-description,
    .TaskCompleted-description {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .SpentTime-container {
        max-width: 278px;
        min-width: 193px;
    }
    .TaskForecast-container,
    .TaskCompleted-container {
        min-width: 220px;
        max-width: 320px;
    }
    .TaskForecast-container,
    .SpentTime-container,
    .TaskCompleted-container {
        width: 100%;
        border: 1.6px solid #FFFFFF;
        box-shadow: 0px 0.8px 2.4px 0px rgba(96, 108, 128, 0.05);
        border-radius: 12.8px;
    }
}
.TimeToTask__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 164px;
    height: 74px;
    position: absolute;
    top: 15px;
    left: 50%;
    right: 50%;
    transform: translate(84%, 0%);
    /* left: -8px; */
    background-color: #FFFFFF;
    z-index: 1000;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px; 
}

.TimeToTask__list {
    display: flex;
    margin: 0;
    margin-top: 9px;
}

.TimeToTask__item:hover .TimeToTask__description {
    color: #5932EA;
}

.TimeToTask__input {
    width: 32px;
    height: 25px;
    font-size: 12px;
    line-height: 16px;
    color: #A4A8B1;
    cursor: pointer;
    text-align: center;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    background-color:#F9FAFB;
    transition: border 0.3s ease-in-out;
    outline: none;
}

.TimeToTask__input:focus,
.TimeToTask__input:hover {
    border: 1px solid #5932EA;
    transition: border 0.3s ease-in-out;
}

.TimeToTask__item {
    display: flex;
    align-items: flex-end;
    height: 16px;
}

.TimeToTask__item p {
    font-size: 12px;
    line-height: 16px;
    color: #A4A8B1;
    margin: 0px 8px 0px 8px;

}

.TimeToTask__item:last-child {
    margin-bottom: 0;
}

.TimeToTask__description {
    font-size: 10px;
    line-height: 14px;
    color: #232360;
    margin-left: 4px;
}

.TimeToTask__container-description {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.TimeToTask__icon {
    width: 12px;
    height: 12px;
    background-color: #5932EA;
    border-radius: 1.5px;
    background-image: url(../../icons/svg/ModalChangingTask/triangle-play.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6px 6px;
}

@media (max-width: 576px) {
    .TimeToTask__container {
        top: 10px;
        left: 50%;
        right: 50%;
        transform: translate(0%, 0%);
    }
}
.dashboard {
    position: relative;
    max-width: 870px;
    height: 109px;
    padding: 18px 36px;
    background: linear-gradient(91.18deg, #5932EA 0%, #0C0C48 100%), #141522;
    border-radius: 10px;
  }
  
  .dashborad__loading-data {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .dashboard__container {
    display: flex;
    max-height: 73px;
    min-width: 311px;
    min-height: 47px;
    justify-content: space-between;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .dashboard__time {
    width: 147px;
    height: 73px;
    text-align: center;
    margin-right: 186px;
  }
  
  .dashboard__progress-bar {
    display: block;
    margin: auto;
    width: 46px;
    height: 46px;
  }
  
  .dashboard__status-bar {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    width: 268px;
    height: 48px;
    margin-right: 60px;
  }
  
  .dashboard__status-bar__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    height: inherit;
    padding: 0;
    margin: 0;
  }
  
  .dashboard__status-bar__time-to-tasks {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
    width: 147px;
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
    color: #FFFFFF;
    animation-name: fadeIn;
    animation-duration: 1.3s;
    animation-fill-mode: both;
  }
  
  .dashboard__status-bar__time-to-tasks p{
    line-height: 22px;
    font-weight: 500;
    font-size: 20px;
  }
  
  .dashboard__time__container {
    width: auto;
    max-width: 175px;
    min-width: 147px;
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  .warning{
    width: 20px;
    height: 20px;
    left: 170px;
    top: 52px;
    background-image: url(../icons/svg/dashboard_ic/warning-img.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: default;
  }
  
  .warning:hover .no_visib{
    opacity: 1;
  }
  
  .notification_warning:hover{
    display: none;
  }
  
  .notification_warning{
    position: absolute;
    top: 150%;
    left: -7px;
    width: 318px;
    height: 44px;
    padding: 8px;
    background-color: #F8C440;
    border-radius: 4px;
    z-index: 0;
    transition: 300ms;
  }
  
  .no_visib{
    opacity: 0;
  }
  
  .visib{
    opacity: 1;
  }
  
  .diamond_in_notifiction{
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 61.54%;
    border-radius: 4px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
  
  .notification_warning_title{
    font-size: 10px;
    font-weight: 700;
    color: #fff;
  }
  
  .notification_warning_time {
    display: inline-flex;
    font-weight: 700;
    gap: 4px;
    margin-left: 4px;
    padding: 2px 4px;
    border-radius: 5px;
    background-color: #94A3BD;
  }

  .notification_warning_description{
    font-size: 10px;
    color: #fff;
  }
  
  .dashboard__status-bar__data {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    animation-name: fadeIn;
    animation-duration: 1.3s;
    animation-fill-mode: both;
  }
  
  .dashboard__status-bar__data__letter {
    line-height: 1;
    font-weight: 500;
    font-size: 12px;
  }
  
  .dashboard__task{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #FFFFFF;
  }
  
  .dashboard__status-bar__item {
    text-align: center;
  }
  
  .dashboard__status-bar__item:last-child {
    margin-right: 0;
  }
  
  .spent_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 91px;
    height: 47px;
  }
  
  .task_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 57px;
    height: 48px;
    margin-right: auto;
    margin-left: 48px;
  }
  
  .chart_item{
    width: 48px;
    height: 48px;
  }
  
  .end_day_item{
    width: 137px;
    height: 57px;
    margin-top: 12.5px;
  }
  
  .dashboard__time__description {
    width: 131px;
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.02em;
    color: #969FFE;
  }
  
  .dashboard__status-bar__description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #969FFE;
  }
  
  .end_day_description {
    flex-direction: column;
    font-size: 14px;
  }

  .before_p{
    margin-top: 4px;
    margin-left: auto;
  }
  
  .before_p :last-child{
    margin-left: 10px;
  }
  
  .dashborad__input-work-time::-webkit-calendar-picker-indicator {
    cursor: pointer;
    margin: 0;
    padding: 0;
    background-image: none;
    background-image: url(../../src/icons/svg/dashboard_ic/vector.svg);
    background-position: center;
  }
  
  .dashborad__input-work-time::-webkit-datetime-edit-minute-field{
    padding: 4px 0;
  }
  
  .dashborad__input-work-time {
    width: 84px;
    height: 32px;
    margin: 4px 0 0 20px ;
    padding: 0 8px;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    outline: none;
    background-color: transparent;
    border: 1px solid rgba(142, 146, 188, 0.5);
    border-radius: 6px;
  }
  .dashborad__input-work-time__mobile,
  .dashborad__input-work-time__mobile-container {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1439px){
    .dashboard {
      width: 100%;
      height: 107px;
      display: block;
      padding: 20px 24px;
      z-index: 9;
    }
    .dashboard__container  {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }
    .dashboard__time {
      max-width: 147px;
      height: 73px;
      margin: 0;
    }
    .dashboard__status-bar {
      width: 268px;
      height: 48px;
      margin: 0;
    }
    .end_day_item {
      width: 139px;
      height: 57px;
      margin-top: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .dashboard {
      width: 100%;
      height: 79px;
      display: block;
      padding: 16px 20px;
      z-index: 9;
    }
    .dashboard__container  {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }
    .dashboard__time {
      width: 86px;
      height: 47px;
      margin: 0;
    }
    .dashboard__time__description {
      height: auto;
      width: 82px;
      font-size: 10px;
      line-height: 1;
      font-weight: 500;
      margin-bottom: 4px;
    }
    .dashboard__status-bar__time-to-tasks {
      align-items: flex-end;
      height: 30px;
      width: 86px;
      font-size: 20px;
      line-height: 1;
    }
    .dashboard__status-bar__time-to-tasks p {
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
      }
      .dashboard__time__description,
      .dashboard__status-bar__time-to-tasks {
        position: relative;
        left: 0;
        top: 0;
      }
    .dashboard__status-bar {
      width: 161px;
      height: 40px;
      margin: 0;
      margin-left: auto;
      margin-right: 24px;
    }
    .dashboard__status-bar__description {
      height: 13px;
      font-size: 10px;
    }
    .dashboard__status-bar__data {
      font-size: 12px;
    }
    .dashboard__status-bar__data__letter {
      font-size: 8px;
    }
    .spent_item {
      width: 57px;
      height: 40px;
    }
    .task_item {
      width: 36px;
      height: 40px;
      margin-right: 12px;
      margin-left: 16px;
    }
    .dashboard__progress-bar {
      width: 40px;
      height: 40px;
    }
    .end_day_item {
      width: 98px;
      height: 42px;
      margin-top: 0;
    }
    .dashborad__input-work-time {
      width: 66px; 
      height: 23px;
      padding: 4px 8px;
      font-size: 10px;
    }
    .chart_item {
     width: 40px;
     height: 40px;
    }
    .warning {
      width: 16px;
      height: 16px;
      left: 110px;
      top: 44px;
    }
    .notification_warning {
      left: -9px;
      width: 205px;
      height: 58px;
      padding: 6px;
    }
    .diamond_in_notifiction {
      bottom: 70.5%;
    }
  }

  @media (max-width: 576px) {
    .dashboard {
        width: 100%;
        height: 87px;
        display: block;
        padding: 8px 12px;
        margin-bottom: 16px;
        z-index: 9;
      }
      .dashboard__container  {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
      }
      .dashboard__time {
        width: 82px;
        height: 42px;
        margin: 0;
      }
      .dashboard__time__description {
        height: auto;
        width: 82px;
        font-size: 10px;
        line-height: 1;
        font-weight: 500;
        margin-bottom: 4px;
      }
      .dashboard__status-bar {
        width: 168px;
        height: 32px;
        margin: 0;
      }
      .dashboard__status-bar__time-to-tasks {
        align-items: flex-end;
        height: 25px;
        width: 78px;
        font-size: 20px;
        line-height: 1;
      }
      .dashboard__status-bar__time-to-tasks p {
        font-size: 10px;
        line-height: 1;
        font-weight: 500;
      }
      .dashboard__time__description,
      .dashboard__status-bar__time-to-tasks {
        position: relative;
        left: 0;
        top: 0;
      }
      .dashboard__status-bar__description {
        font-size: 10px;
      }
      .dashboard__status-bar__data {
        font-size: 12px;
      }
      .dashboard__status-bar__data__letter {
        font-size: 8px;
      }
      .spent_item {
        width: 57px;
        height: 32px;
      }
      .task_item {
        width: 48px;
        height: 32px;
        margin-right: 12px;
        margin-left: 20px;
      }
      .chart_item {
        width: 32px;
        height: 32px;
      }
      .dashboard__progress-bar {
        width: 32px;
        height: 32px;
      }
      .end_day_item {
        width: 180px;
        height: 21px;
        margin-top: 0;
        margin-left: auto;
      }
      .end_day_description {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .before_p {
        display: flex;
        width: 80px;
        align-items: center;
        justify-content: space-between;
        margin: 0;
      }
      .before_p :last-child {
        margin-left: 0px;
      }
      .dashborad__input-work-time {
        display: none;
      }
      .dashborad__input-work-time__mobile-container {
        display: flex;
        align-items: center;
        width: 60px;
        padding: 0px 3px;
        height: 22px;
        
        outline: none;
        background-color: transparent;
        border: 1px solid rgba(142, 146, 188, 0.5);
        border-radius: 6px;
      }
      .dashborad__input-work-time__mobile {
        display: block;
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 10px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .dashborad__input-work-time__mobile::-webkit-calendar-picker-indicator {
        display: none;
      }
      .warning {
        width: 16px;
        height: 16px;
        left: 95px;
        top: 30px;
      }
      .notification_warning {
        width: 205px;
        height: auto;
        left: -9px;
      }
      .diamond_in_notifiction {
        bottom: 72.54%;
      }
  }

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
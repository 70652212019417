.task_table_section {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 575px) {
  .task_table_section {
    margin-left: 34px;
    margin-right: 6px;
  }
}

/* @media (max-width: 400px) {
  .task_table_section {
    margin-right: 20px;
  }
} */

table {
  width: 100%;
}

.table_head {
  color: #FFF;
  background: #5932EA;
}

.table_h_tr {
  height: 54px;
}

@media (max-width: 768px) {
  .table_h_tr {
    height: 44px;
  }
}

@media (max-width: 400px) {
  .table_h_tr {
    height: 36px !important;
  }
}

.table_head::after {
  margin-bottom: -5px;
  content: "@";
  display: block;
  line-height: 17px;
  text-indent: -99999px;
  background: #F8FAFB;
}

.table_th {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.17px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .table_th {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .table_th {
    max-width: 100px !important;
    min-width: 50px !important;
    width: 120px !important;
    white-space: nowrap;
  }
}

@media (max-width: 400px) {
  .table_th {
    font-size: 9px;
    padding-left: 5px;
  }
}

.th_left {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

@media (max-width: 500px) {
  .th_left {
    max-width: 16px !important;
    min-width: 16px !important;
    width: 16px !important;
    text-align: center;
  }
}

.th_right {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

@media (max-width: 1220px) {
  .th_right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.table_th_multi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.time {
  font-size: 14px;
}

@media (max-width: 768px) {
  .time {
    font-size: 9px !important;
  }
}

.th_center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

@media (max-width: 768px) {
  .th_center {
    gap: 4px;
  }
}

@media (max-width: 400px) {
  .th_center {
    gap: 2px;
  }
}


.table_body {
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 1px rgba(89, 50, 234, 0.35);
  border-radius: 12px;
  box-shadow: 0 0 0 1px rgba(89, 50, 234, 0.35);
}

.table_tr:last-child {
  border-bottom: none !important;
}

.table_tr {
  border-bottom: 0.5px solid rgba(89, 50, 234, 0.35);
  border-radius: 12px;
}

.table_body_td {
  height: 52px;
  max-width: 300px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 20px;
  overflow: hidden;
  font-feature-settings: 'clig' off, 'liga' off;
  color: #232360;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-hover {
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}

.item-hover:hover {
  color: #5932EA;
  transition: color 0.1s ease-in-out;
}

@media (max-width: 768px) {
  .table_body_td {
    max-width: 130px;
    height: 44px;
    font-size: 10px !important;
  }
}

@media (max-width: 400px) {
  .table_body_td {
    max-width: 100px;
    height: 34px;
    font-size: 8px !important;
  }
}


.td_center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.load_data {
  width: 100%;
  display: flex;
  justify-content: center;
}

.load_btn {
  margin-top: 24px;
  width: 140px;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid #E1E4ED;
  color: #232360;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.load_btn:hover {
  border: 1px solid #5932EA;
}

.time_btn {
  color: #AC98F6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.17px;
}

.load_section {
  height: 378px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.time_norm {
  color: #232360;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.time_warn {
  color: #F85640;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.time_warn_min {
  color: #F85640;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.time_norm_min {
  color: #232360;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .time_norm {
    font-size: 10px;
  }

  .time_norm_min {
    font-size: 8px;
  }

  .time_warn {
    font-size: 10px;
  }

  .time_warn_min {
    font-size: 8px;
  }
}

.fact_plan_time {
  color: #AC98F6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.17px;
}

.th_swipe {
  display: none;
  width: 51px;
  text-align: center;
}

@media (max-width: 1220px) {
  .th_swipe {
    display: table-cell;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

@media (max-width: 550px) {
  .th_swipe {
    max-width: 71px !important;
    min-width: 71px !important;
    width: 71px !important;
  }
}

@media (max-width: 768px) {
  .th_swipe {
    padding-left: 5px !important;
  }
}

.swipe_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
}

@media (max-width: 768px) {
  .swipe_block {
    padding: 3px 5px !important;
  }
}

.cl_hidden {
  display: table-cell;
}

@media (max-width: 1220px) {
  .cl_hidden {
    display: none;
  }
}

.cl_show {
  display: table-cell;
}

@media (max-width: 1220px) {
  .cl_show {
    display: table-cell;
  }
}

@media (max-width: 550px) {
  .cl_show {
    width: 120px;
  }
}


.swipe_btn {
  cursor: pointer;
}

.eye_icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .eye_icon {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 500px) {
  .table_th {
    font-size: 9px;
  }
}

.donat_block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.percent {
  color: var(---, #232360);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}

@media (max-width: 1600px) and (min-width: 1280px) {
  .table_th,
  .time {
    font-size: 14px;
  }
  .table_body_td,
  .percent {
    font-size: 12px;
  }
  .time_norm,
  .time_warn,
  .time_warn_min,
  .time_norm_min {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 856px) {
  .table_th,
  .time {
    font-size: 12px;
  }
  .table_body_td,
  .percent {
    font-size: 10px;
  }
  .time_norm,
  .time_warn,
  .time_warn_min,
  .time_norm_min {
    font-size: 10px;
  }
}

@media (min-width: 857px) and (max-width: 1024px) {
  .table_th,
  .time {
    font-size: 14px;
  }
  .table_body_td,
  .percent {
    font-size: 12px;
  }
  .time_norm,
  .time_warn,
  .time_warn_min,
  .time_norm_min {
    font-size: 12px;
  }
}


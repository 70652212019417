
.stats-main-container {
    margin-left: 50px;
}

.stats-header {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #232360;
    margin: 0;
    margin-right: 10px;
}

.stats-main-container__header {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.stats-main-container__first-line,
.stats-main-container__second-line {
    width: 1016px;
}

.stats-period-select {
    position:static;
    display: flex;
    align-items: center;
    max-width: 135px;
    height: 40px;
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #EBEEF2;
    background-color: rgba(255, 255, 255);;
    background-size: 14px 8px;
    background-position: 100px center;
    background-image: none;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0px 1.2530221939086914px 3.759066104888916px 0px #606C800D;
}

.stats-period-select p {
    font-size: 16px;
    margin-right: 10px;
}

.first-line__list {
    width: 100%;
    display: flex;
    gap: 28px;
    justify-content:space-between;
    margin-bottom: 36px;
}


.first-line__item,
.top-forecast__item {
    background: #FFFFFF;
    border: 1px solid #EBEEF2;
    box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
    border-radius: 16px;
}
.completed-task-stats {
    width: 260px;
    padding: 16px 24px;
}
.completed-task-stats__info,
.forecast-accuracy-stats__info,
.top-tags-stats__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forecast-accuracy-stats__info {
    padding: 0px 14px;
}

.completed-tasks-stats__select {
    position:static;
    display: flex;
    align-items: center;
    width: 112px;
    height: 32px;
    padding-left: 8px;
    font-weight: 600;
    cursor: pointer;
    background-size: 11px 6px;
    background-position: 85px center;
}

.forecast-accuracy-stats {
    width: 347px;
    padding: 16px 24px;
}
.top-tags-stats {
    width: 371px;
    padding: 16px 24px;
}

.second-line__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 36px;
}

.second-line__list-item {
    display: flex;
    width: 100%;
    height: 300px;
}

.toptag-bg {
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    min-width: 246px;
    max-width: 581px;
}

.bestforec-bg,
.badforec-gb {
    flex-shrink: 2;
    flex-basis: auto;
    width: 100%;
    min-width: 248px;
    max-width: 395px;
}

.completed-tasks-per-interval {
    width: 712px;
    height: 255px;
    background: #FFFFFF;
    border: 1.04106px solid #E1E4ED;
    border-radius: 16px;
}
.top-forecasts {
    width: 288px;
    height: 266px;
}

.top-forecast__item {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.top-forecast__item p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #5932EA;
}

.best-forecast,
.bad-forecast {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}

.best-forecast {
    color: #029222;
}

.bad-forecast {
    color: #F85640;
}

.noData-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.noData-icon {
    width: 28px;
    height: 28px;
    background-image: url(../../../../icons/svg/noDataIcon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 6px;
}

.noData-description {
    font-weight: 600;
    font-size: 28px;
    line-height: 98px;
    color: #FFFFFF;
}


.stats-pick-date-range {
    position: absolute;
    display: flex;
    top: 600px;
    flex-direction: column;
    align-items: center;
    width: 342px;
    border-radius: 12px;
    padding: 20px 26px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    z-index: 999;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

.stats-pick-date-range__list {
    display: flex;
}


.stats-pick-date-range__item {
    width: 100px;
    height: 22px;
    color: #232360;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border-right: 1px solid #000;
}


.stats-pick-date-range__list-for-statistics {
    display: flex;
    justify-content: center;
    width: 290px;
    height: 22px;
    margin: 0;
    margin-bottom: 24px;
}

.calendar-icon-for-statistic {
    display: block;
    width: 19px;
    height: 19px;
    margin-right: 15px;
    background-image: url(../../../../icons/svg/CalendarDDSelect/calendar-item.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.get-today-date-for-statistic,
.get-week-date-for-statistic,
.get-month-date-for-statistic {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #232360;
    cursor: pointer;
    transition: font-weight 0.1s ease-in-out;
}

.get-today-date-for-statistic:hover,
.get-week-date-for-statistic:hover,
.get-month-date-for-statistic:hover {
    font-weight: 600;
    transition: font-weight 0.1s ease-in-out;
}

.get-today-date-for-statistic {
    width: 86px;
}

.get-week-date-for-statistic {
    width: 58px;
}

.calendar__get-date-from-btns-for-statistics {
    width: 283px;
    height: 22px;
    display: flex;
    align-items: center;
}

.active-get-date-for-statistic {
    font-weight: 600;
    transition: font-weight 0.1s ease-in-out;
}
.calendar__separator-for-statistics {
    width: 1px;
    height: 17px;
    background-color: #E5E7EB;
    margin-left: 13px;
    margin-right: 13px;
}

.stats-percent-span {
    font-size: 36px;
    line-height: 75px;
}

.stats-range {
    margin-left: 10px;
    padding: 0px 5px;
    border-radius: 8px;
    color: #232360;
    background-color: #ebecfd;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@media (max-width: 1023px) {
    .stats-header {
        font-size: 16px;
        line-height: 1;
    }
    .stats-period-select {
        height: 32px;
        padding: 8px;
        background-position: 75px center;
    }
    .stats-period-select p {
        font-size: 12.8px;
        line-height: 1;
    }
    .input-select {
        border-radius: 6px;
    }
}



@media (max-width: 576px) {

    .stats-main-container {
        margin: 0;
    }
    .stats-range {
        display: none;
    }
    .first-line__list{
        margin-bottom: 12px;
    }
    .second-line__list {
        margin-bottom: 0;
    }
    .first-line__list,
    .second-line__list {
        width: 100%;
        gap: 12px;
        flex-direction: column;
        align-items: center;
        padding-left: 34px;
        padding-right: 6px;
    }
    .first-line__list-item,
    .second-line__list-item {
        height: 173px;
    }
    .first-line__list-item:last-child,
    .second-line__list-item:last-child {
        margin-bottom: 0px;
    }
    .stats-main-container__header {
        position: relative;
        margin-left: 25px;
        margin-right: 34px;
        padding-left: 9px;
        margin-bottom: 12px;
    }
    .stats-pick-date-range {
        top: 45px;
        left: 0;
        right: 0;
    }
    .noData-icon {
        width: 20px;
        height: 20px;
    }
    .noData-description {
        font-weight: 600;
        font-size: 22px;
        line-height: 1;
        color: #FFFFFF;
    }
    .bestforec-bg,
    .badforec-gb {
        max-width: 100%;
    }
}

@media (min-width: 400px) and (max-width: 576px){
    .first-line__list-item,
    .second-line__list-item {
        height: 193px;
    }
 }

@media (max-width: 1600px) and (min-width: 1280px) {
    .toptag-bg {
        max-width: 464px;
    }
    .first-line__list {
        margin-bottom: 24px;
    }
    .first-line__list,
    .second-line__list {
        gap: 28px;
    }
    .second-line__list-item {
        height: 220px;
    }
}

@media (min-width: 768px) and (max-width: 856px) {
    .first-line__list-item,
    .second-line__list-item {
        height: 100%;
    } 
    .first-line__list-mobile,
    .second-line__list-mobile,
    .third-line__list-mobile {
        gap: 22px;
    }
    
}

@media (min-width: 857px) and (max-width: 1024px) {
    .first-line__list-mobile,
    .second-line__list-mobile,
    .third-line__list-mobile {
        gap: 28px;
    }
    .first-line__list-item,
    .second-line__list-item {
        height: 100%;
    }
    .toptag-bg {
        min-width: 362px;
    } 
}

@media (min-width: 768px) and (max-width: 1024px)  {
    .stats-main-container {
        display: block;
        margin-left: 56px;
    }
    .stats-main-container__header {
        margin-bottom: 19px;
    }
    .stats-pick-date-range {
        top: 350px;
    }
    .first-line__list-mobile,
    .second-line__list-mobile,
    .third-line__list-mobile {
        display: flex;
        width: 100%;
    }
    .bestforec-bg,
    .badforec-gb {
        width: 100%;
        min-width: 220px;
        max-width: 320px;
    }
    .noData-description {
        font-size: 20px;
    }

}
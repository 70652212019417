/* background:  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p0)*3.6deg),#0000 0); */

.flex-pie {
  min-width: 128px;
  height: 125px;
  position: relative;
  margin-left: 24px;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;  
}

.pie-3 {
  --c: #95A4FC;
  z-index: 0;
}

.pie-2 {
  --c: #5932EA;
  z-index: 1;
}

.pie-1 {
  --c: #C6C7F8;
  z-index: 2;
}

.pie-0 {
  --c: #232360;
  z-index: 3;
}


.pie-0, .pie-1, .pie-2, .pie-3 {
  --w: 118px;
  --b: 20px;
  width: var(--w);
  aspect-ratio: 1;
  position: absolute;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  border-radius: 50%;
  /* border: 1px solid #fff; */
}

/* .pie-0 div, .pie-1 div, .pie-2 div, .pie-3 div {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: linear-gradient(313.12deg, #5932EA 0.01%, #40C0F8 100.01%);
  z-index: 99;
  border: 1px solid #fff;
} */


.pie-0:before, .pie-1:before, .pie-2:before, .pie-3:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
  mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}

.pie-0:before {
  background: conic-gradient(var(--c) calc(var(--p0)*3.6deg),#0000 0);
}

.pie-1:before {
  background:  conic-gradient(var(--c) calc(var(--p1)*3.6deg),#0000 0);
  
}

.pie-2:before {
  background:  conic-gradient(var(--c) calc(var(--p2)*3.6deg),#0000 0);
  
}

.pie-3:before {
  background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,  
  conic-gradient(var(--c) calc(var(--p3)*3.6deg),#0000 0);
  transform: rotate(calc(var(--p3)* 3.6deg));
}


/* .pie-2:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: calc(50% - var(--b)/2);
  background: var(--c);
  transform: rotate(0deg) translateY(calc(50% - var(--w)/2));
}
 */
/* .pie-0:after {
  transform: rotate(calc(var(--p0)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.pie-1:after {
  transform: rotate(calc(var(--p1)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.pie-2:after {
  transform: rotate(calc(var(--p2)*3.6deg)) translateY(calc(50% - var(--w)/2)); 
}

.pie-3:after {
  transform: rotate(calc(var(--p3)*3.6deg)) translateY(calc(50% - var(--w)/2.4)); 
}

.pie-0:after, .pie-1:after, .pie-2:after, .pie-3:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: calc(50% - var(--b)/2);
  background: var(--c); 
}  */

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@media (max-width: 576px) {
  .flex-pie {
    min-width: 88px;
    height: 88px;
    margin: 0;
  }
  .pie-0, .pie-1, .pie-2, .pie-3 {
    --w: 80px;
    --b: 15px;
  }
}

@media (min-width: 768px) and (max-width: 856px) {
  .flex-pie {
    min-width: 64px;
    height: 64px;
    margin-left: 20px;
  }
  .pie-0, .pie-1, .pie-2, .pie-3 {
    --w: 64px;
    --b: 10px;
    margin: 0;
  }
}

@media (min-width: 857px) and (max-width: 1024px) {
  .flex-pie {
    min-width: 96px;
    height: 96px;
    margin-left: 24px;
  }
  .pie-0, .pie-1, .pie-2, .pie-3 {
    --w: 96px;
    --b: 18px;
    margin: 0;
  }
}

@media (max-width: 1600px) and (min-width: 1280px) {
  .flex-pie {
      min-width: 128px;
  }
  
}
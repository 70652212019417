.donat {
  fill: none;
  stroke-width: 2px;
}

.donat__background {
  stroke: #d0dbe5;
}

.donat__progress_red {
  stroke: #F85640;
  stroke-linecap: round;
}

.donat__progress_green {
  stroke: #32EAA8;
  stroke-linecap: round;
}

.donat__progress_orange {
  stroke: #F8C440;
  stroke-linecap: round;
}

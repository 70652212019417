.alert_container {
    position: relative;
    padding: 16px;
    border-radius: 4px;
    margin: 0;
    transition: all 1.5s ease-in;
}

.alert_container.successAl {
    background-color: #D1E7DD;
    color: #0F5132;
    border: 1px solid #BADBCC;
}

.alert_container.warningAl {
    background-color: #FFF3CD;
    color: #664D03;
    border: 1px solid #FFECB5;
}
.alert_container.danger { 
    background-color: #F8D7DA;
    color: #842029;
    border: 1px solid #F5C2C7;
}
.alert_container.system {}

.alert_countdown {
    bottom: 0;
    left: 0;
    border-radius: 4px;
    position: absolute;
    opacity: 0.4;
    height: 2px;
    transition: width 1s linear;
}

.alert_countdown.successAl {
    background-color: #0F5132;
}

.alert_countdown.warningAl {
    background-color: #664D03;
}

.alert_countdown.danger {
    background-color: #842029;
}
.calendar__container {
    width: 327px;
    max-height: 441px;
    padding: 20px 28px;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px; 
}

.calendar__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 30px;
}

.calendar__item-indent {
    width: 100%;
}

.calendar__separator {
    width: 1px;
    height: 17px;
    background-color: #E5E7EB;
}

.calendar__get-time-from-input__field,
.calendar__get-time-from-input__field__focused {
    display: flex;
    align-items: center;
    width: 145px;
    height: 34px;
    background: #F9FAFB;
    border-radius: 8px;
    transition: border 0.3s ease-in-out;
}

.calendar__get-time-from-input__field {
    border: 1px solid #E5E7EB;
    transition: border 0.3s ease-in-out;
}

.calendar__get-time-from-input__field__focused {
    border: 1px solid #5932EA;
    transition: border 0.3s ease-in-out;
}

.calendar__remove-btn {
    display: block;
    width: 35px;
    height: 34px;
    border-left: solid 1px #E5E7EB;
    border-radius: 8px;
    cursor: pointer;
    background-image: url(../../icons/svg/CalendarDDSelect/cancel-btn.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.calendar__remove-btn:hover {
    border-left: solid 1px #c2c2c2;
}

.calendar__get-date-from-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
}

.get-today-date,
.get-tomorrow-date,
.get-nodata-date {
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #232360;
    cursor: pointer;
    transition: font-weight 0.1s ease-in-out;
}

.get-today-date {
    width: 65px;
}

.get-tomorrow-date {
    width: 57px;
}

.get-nodata-date {
    width: 78px;
}

.get-today-date:hover,
.get-tomorrow-date:hover,
.get-nodata-date:hover {
    font-weight: 600;
    transition: font-weight 0.1s ease-in-out;
}

.active-get-date {
    font-weight: 600;
    transition: font-weight 0.1s ease-in-out;
}


.calendar__get-time-from-input {
    display: flex;
    align-items: center;
    gap: 16px;
}

.calendar__get-time {
    width: 135px;
    border: none;
    outline: none;
    padding: 0;
    padding-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #232360;
    background-color: transparent;
    background-image: url(../../icons/svg/CalendarDDSelect/Union-item.svg);
    background-repeat: no-repeat;
    background-position: 10px center;
    text-indent: 0px;   
    transition: all 0.3s ease-in-out;
}

.calendar__get-time::placeholder {
    font-size: 12px;
    line-height: 12px;
    color: #A4A8B1;
    transform: translateY(-2px);
}

.calendar__get-time__shorted {
    width: 110px;
    background-image: none;
}

.calendar__get-time:focus {
    background-image: none;
    padding-left: 15px;
    transition: border 0.3s ease-in-out;
}

.calendar__get-time:focus::-webkit-input-placeholder {
    text-indent: 0px;
    transition: all 0.7s ease-in-out;
}


@media (max-width: 576px) {
    /* .calendar__container {
        left: 0;
        right: 0;
    } */
}

@media (min-width: 768px) and (max-width: 991px) {
    .calendar__container {
        top: 150px
      }
}
.donation-btn {
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 8px 10px;
    background-color: #8B3FFD;
    margin-left: auto;
    margin-right: auto;
}
.donation-btn__container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.donation-btn__logo {
    width: 20px;
    height: 20px;
}

.donation-btn__description {
    color: #F2EEFF;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.2;
}

.page-container {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0px auto;
  }

.page-info {
  display: flex;
  position: relative;
  align-items: center;
}

.page-title{
    font-weight: 400;
    font-size: 28px;
    color: #232360;
  }

@media (min-width: 992px) and (max-width: 1439px) {
  .page-container {
    max-width: 870px;
    flex-grow: 1;
    gap: 36px;
    margin: 32px 36px auto 36px;
  }
  .page-title {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
    .page-container {
        flex-grow: 1;
        margin: 20px 20px 0px 20px;
        gap: 12px;
    }
    .page-title {
        font-size: 16px;
        line-height: 1;
      }
}

@media (min-width: 768px) and (max-width: 991px) {
    .page-container {
      gap: 24px;
      flex-grow: 1;
      margin: 32px 28px auto 28px;
    }
    .page-title {
        font-size: 20px;
      }
}


.contextMenu {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    min-width: 228px;
    min-height: 100px;
    padding: 18px 0px;
    z-index: 999;
    font-size: 14px;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px;
    background-color: #FFFFFF;
    user-select: none;
    opacity: 0;
    transform:translateX(100px);
    animation: contextFade 0.3s forwards;    
}

@keyframes contextFade {
    0% { 
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.contextMenu__second-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.contextMenu__second-item {
    display: flex;
    color: #232360;
    gap: 8px;
    cursor: pointer;
    padding: 0px 20px;
}

.removeIcon,
.dublicateIcon {
    fill: #232360;
}
.contextMenu__first-item__description {
    font-size: 12px;
    font-weight: 400;
    color: #A4A8B1;
    line-height: 1.3;
    margin-bottom: 10px;
    cursor: default;
}

.contextMenu__second-item:hover {
    background-color: #F2EEFF;
}

.contextMenu__second-item__description {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    line-height: 16px;
    color: #232360;
    transition: color 0.2s ease-in-out;
}

.contextMenu__first-item__priority-container {
    display: flex;
    flex-direction: row;
}

.contextMenu__first-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    margin: 0;
}

.contextMenu__first-item {
    padding: 0px 20px;
}

.contextMenu__first-item__btns-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}


.btns-block__calendar-icon {
    cursor: pointer;
    padding: 5px;
    fill: #5932EA;
    border-radius: 6px;
    transition: all 0.1s ease;
}

.btns-block__calendar-icon:hover {
    fill: #FFFFFF;
    background-color: #5932EA;
    transition: all 0.1s ease;
}

.btns-block__tomorrow,
.btns-block__no-date {
    cursor: pointer;
    color: #232360;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    transition: font-weight 0.1s ease-in-out;
}

.btns-block__tomorrow:hover ,
.btns-block__no-date:hover {
    font-weight: 700;
    transition: font-weight 0.1s ease-in-out;
}

.btns-block__tomorrow {
    width: 45px;
}

.btns-block__no-date {
    width: 61px;
}

.btns-block__separator {
    width: 1px;
    height: 17px;
    background-color: #E5E7EB;
}

.contextMenu__first__priority-list {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.contextMenu__first__priority-list__item {
    display: flex;
    align-items: center;
    min-width: 20px;
    max-width: 100px;
    height: 20px;
    padding: 2px 4px;
    border-radius: 50%;
    cursor: pointer;
}

.current-priority {
    border-radius: 6px;
    animation-name: priorityIn;
    animation-duration: 0.1s;
    animation-fill-mode: both;
}

.current-priority .priority-list__item-description {
    display: block;
    animation-name: priorityDescrIn;
    animation-duration: 0.3s;
    animation-fill-mode:forwards;
}

.priority-list__item-description {
    display: none;
    flex: none;
    opacity: 0;
    overflow: hidden;
    font-size: 10px;
    color: #FFFFFF;
    line-height: 1;
}

.item-1 {
    background-color: #F85640;
}

.item-2 {
    background-color: #F8C440;
}

.item-3 {
    background-color: #32EAA8;
}

.item-4 {
    background-color: #E1E4ED;
}

.item-4 p {
    color: #A4A8B1;
}

@keyframes priorityIn {
    0% {
        width: 20px;
        border-radius: 6px;
    }
    100% {
        width: 100%;
        border-radius: 6px;
    }
}

@keyframes priorityDescrIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
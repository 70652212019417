.error {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    width: 100%;
    color: red;
    margin-top: 5px;
}

.privacy-link {
    margin-left: 3px;
}

.privacy-check:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: default;
}

.privacy-label {
    font-size: 12px;
    line-height: 16px;
    color: #94A3BD;
}

.privacy-label a {
    color: #94A3BD;
}

.privacy-label a:hover {
    color: #5932EA
}
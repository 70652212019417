.success_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    flex-direction: column;
    height: 92vh;
}

.success_body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.success_icon {
    width: 40px;
    height: 40px;
}

.success_text {
    color: var(--unnamed, #232360);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 150% */
}

.success_footer {
    background: #FFF;
    display: flex;
    text-align: center;
}

.footer_text {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.42px;
    margin-left: 10px;
}

.footer_link {
    margin-left: 50px;
}

@media (max-width: 576px) {
    .success_container {
        height: 95vh;
        
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .success_container {
        height: 95vh;
    }
}
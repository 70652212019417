
.todo-list__timer {
    padding: 8px 32px 8px 0;
    cursor: default;
}

.startTime-btn,
.pauseTime-btn {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
}
.startTime-btn {
    background-color: #5932EA;
    background-image: url(../icons/svg/ModalChangingTask/play-timer-btn.svg);
    background-size: cover;
    transition: all 0.3s ease-in-out;
}

.startTime-btn:disabled {
    background-color: #A4A8B1;
    background-image: url(../icons/svg/ModalChangingTask/play-timer-btn-disabled.svg);
    background-size: cover;
    transition: all 0.3s ease-in-out;
}

.pauseTime-btn {
    background-color: #ded9f3;
    background-image: url(../icons/svg/ModalChangingTask/pause-timer-btn.svg);
    background-size: cover;
    transition: all 0.3s ease-in-out;
}

.currenRunTime {
    width: 45px;
    
    outline: none;
    text-align: center;
    background-color: transparent;
    color: #232360;
}

.timer-info-container {
    display: flex;
    align-items: center;
    box-sizing: content-box;
}

.timer-info-container__separator {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #232360;

}

.timer-container {
    display: flex;
    align-items: center;
    color: #232360;
    font-size: 14px;
    gap: 18px;
    box-sizing: content-box;
    line-height: 33.6px;
}

.timer-container__scheduled {
    display: flex;
    
}

.scheduledRunTime {
    border: none;
    outline: none;
    background-color: transparent;
    max-width: 50px;
    font-size: 15px;
}

.scheduledRunTime__minutes {
    text-align: start;
}

.elapsedRunTime__container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 72.5px; */
    height: 16px;
    box-sizing: content-box;
    border: 1px solid transparent;
    padding: 8px;
    border-radius: 8px;
    transition: border 0.2s ease-in-out;
}

.scheduledRunTime__container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 51.55px; */
    height: 16px;
    box-sizing: content-box;
    border: 1px solid transparent;
    padding: 8px;
    border-radius: 8px;
    transition: border 0.2s ease-in-out;
}

.scheduledRunTime__hours,
.scheduledRunTime__minutes,
.scheduledRunTime__secconds {
    width: 18px;
    border: none;
    outline: none;
    padding: 0;
    text-align: center;
    background-color: transparent;
    color: #232360;
}

.scheduledRunTime__hours::selection,
.scheduledRunTime__minutes::selection,
.scheduledRunTime__secconds::selection {
    background-color: rgb(167, 180, 251, 0.66);

}
.scheduledRunTime__hours-over,
.scheduledRunTime__minutes-over,
.scheduledRunTime__secconds-over {
    width: 17px;
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    color: #F85640;
}

.scheduledRunTime__hours-over::selection,
.scheduledRunTime__minutes-over::selection,
.scheduledRunTime__secconds-over::selection {
    background-color: rgb(248, 86, 64, 0.15);
}

.scheduledRunTime__info,
.scheduledRunTime__description,
.elapsedRunTime__info,
.elapsedRunTime__description {
    color: #232360;
}

.scheduledRunTime__span,
.elapsedRunTime__span {
    display: flex;
    align-items: flex-end;
}

.scheduledRunTime__info,
.elapsedRunTime__info {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
}

.scheduledRunTime__description,
.elapsedRunTime__description {
    font-size: 12px;
    line-height: 12px;
    margin-left: 5px;
}

.elapsedRunTime__description {
    margin-right: 4px;
}

.scheduledRunTime__info {
    margin-left: 4px;
}

.split-over {
    color: #F85640;
}

.countdown-timer {
    color: #F85640;
    font-size: 12px;
    line-height: 16px;
}

/******OutSideTimer******/
.outsidetimer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    background-color: #ded9f3;
    border-radius: 8px;
}

.outsidetimer-container__component-list {
    display: flex;
    justify-content: center;
}

@media (max-width: 576px) {
    .todo-list__timer {
        padding: 8px 12px;
    }
    .runTime-container__percentTime {
        display: none;
    }
    .startTime-btn, .pauseTime-btn {
        background-color: transparent;
        width: 28px;
        height: 28px;
        transition: none;
    }
    .timer-container {
        gap: 0px;
    }
    .countdown-timer {
        font-size: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) { 
    .todo-list__timer {
        padding: 8px 12px;
    }

    .timer-info-container {
        gap: 2px;
        margin-bottom: 2px;
    }

    .runTime-container {
        gap: 12px;
    }

    .scheduledRunTime__info {
        margin-left: 0;
    }

    .scheduledRunTime__info,
    .elapsedRunTime__info {
        font-size: 10px;
        line-height: 10px;
    }

    .elapsedRunTime__description {
        margin-right: 0;
    }

    .scheduledRunTime__description,
    .elapsedRunTime__description {
        font-size: 8px;
        line-height: 10px;
        margin-left: 4px;
    }

    .timer-info-container__separator {
        margin-left: 0;
        margin-right: 0;
        font-size: 10px;
        line-height: 10px;
    }

    .runTime-container__percentTime {

        margin: 0;
    }
    .startTime-btn {
        margin: 0;
    }

    .timer-container {
        gap: 21px;
    }
}
.not-found-page {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .not-found-page__container {
    padding-top: 64px;
    margin: 0px 28px;
  }

  .not-found-page__info {
    display: flex;
    flex-direction: column;
    gap: 144px;
  }
  
  .not-found-page__description,
  .not-found-page__description-main {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #232360;
  }

  .not-found-page__conatiner-btns {
    display: flex;
    gap: 56px 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .not-found-page__link-to {
    color: #5932EA;
  }
  
  .not-found-page__link-to:hover {
    color: #546FFF;
  }
  
  .not-found-page__cause {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
  }
  
  .not-found-page__cause-header {
    font-weight: 600;
    font-size: 288px;
    line-height: 230px;
    color: #5932EA;
  }
  
  .not-found-page__cause-description {
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    color: #5932EA;
  }
  
  @keyframes fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  .not-found-page__cause-header,
  .not-found-page__cause-description {
    animation-name: fade;
    animation-duration: 4s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 576px) {
    .not-found-page__info {
        flex-direction: column-reverse;
        gap: 56px;
    }
    .not-found-page__cause {
        gap: 28px;
    }
    .not-found-page__cause-header {
        font-size: 140px;
        line-height: 113px;
    }
    .not-found-page__cause-description {
        font-size: 20px;
        line-height: 27px;
        font-weight: 400;
    }
    .not-found-page__description {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    .not-found-page__description-main {
      display: none;
    }
  }

  @media (min-width: 577px) {
    .not-found-page__conatiner-btns {
        display: none;
    }
  }
.tags_section {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .tag_name {
    height: 24px;
    width: max-content;
    border-radius: 8px;
    background: #F2EEFF;
    padding: 3px 7px 3px 7px;
    color: #5932EA;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 768px) {
    .tag_name {
      font-size: 10px !important;
    }
  }
  
  @media (max-width: 400px) {
    .tag_name {
      height: 18px;
      font-size: 8px !important;
    }
  }
  
  .tag_name_empty {
    width: 100%;
    text-align: center;
    color: #94A3BD;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }

  @media (max-width: 768px) {
    .tag_name_empty {
      font-size: 10px !important;
    }
  }
  
  @media (max-width: 400px) {
    .tag_name_empty {
      font-size: 8px !important;
    }
  }
  
  
  
  .dop_tag {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #E1E4ED;
    background: #FFF;
    color: #232360;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }

  .dop_tag:hover {
    border: 1px solid #5932EA;
    background: #AC98F6;
    color: #FFF;
  }

  .dop_tag_hide {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .dop_tag_hide:hover {
    border: 1px solid #5932EA;
    border-radius: 4px;
    background: #AC98F6;
    fill: #FFF;
  }

  .tags_list {
    max-width: 170px;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
  

  @media (max-width: 1600px) and (min-width: 1280px) {
    .tag_name_empty {
      font-size: 10px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 856px) {
    .tag_name_empty {
      font-size: 10px;
    }
    
  }
  
  @media (min-width: 857px) and (max-width: 1024px) {
    .tag_name_empty {
      font-size: 10px;
    }
  }
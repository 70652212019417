@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('/src/fonts/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  src: url('/src/fonts//OpenSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url('/src/fonts/OpenSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url('/src/fonts/OpenSans-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('/src/fonts/OpenSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 800;
  src: url('/src/fonts/OpenSans-ExtraBold.woff2') format('woff2');
}

.lato-container {
  font-family: 'Lato';
}

.opensans-container {
  font-family: 'OpenSans';
}

.descr {
  color: #546FFF;
}

body {
  margin: 0;
  font-family: 'OpenSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8FAFB;
}

p {
  margin: 0;
}

ul {
  list-style: none;
}

a {
  color: #b4b3b3;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.active {
  color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

.header {
  display:  flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #E8E8E8;
}

.header__logo,
.header__logo-nologin {
  margin-left: 24px;
}

.header__buttons {
  display: flex;
  gap: 8px;
  margin-right: 52px;
}

.header__nav-btns {
 display: flex;
 margin-right: 52px;
}

.header__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.btn-to-register {
  width: 149px;
  margin-right: 8px;
  border: 1px solid #5932EA;
  padding: 10px 25px;
  color: #5932EA;
}

.btn-to-login {
  width: 91px;
  border: 1px solid #5932EA;
  background-color: #5932EA;
  padding: 10px 20px;
  color: #FFFFFF;
  font-weight: 600;
}

.btn-to-register:hover {
  background-color: #5932EA;
  color: #FFFFFF;
}

.btn-to-login:hover {
  background-color: #FFFFFF;
  color: #5932EA;
}

/* Nav-меню */
.settings-page__nav-list {
  display: flex;
  flex-direction: row;
  min-width: 316px;
  max-width: 724px;
  margin: 0;
  margin-bottom: 40px;
}

.settings-page__nav-item {
  margin-right: 32px;
}

.settings-page__nav-item a:hover {
  color: #232360;
}

.nav-link {
  color: #6A7181;
}

.nav-link-active {
  color: #232360;
  text-decoration: none;
}


.nav-toggle {
  position: absolute;
  left: 150px;
  top: 1em;
  padding: 0.5em;
  background: inherit;
  color: #dadada;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1;
  z-index: 2001;
  -webkit-transition: color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out;
  transition: color .25s ease-in-out;
}

.nav-toggle:after {
  content: '\2630';
  text-decoration: none;
}

.nav-toggle:hover {
  color: #f4f4f4;
}

[id='nav-toggle'] {
  position: absolute;
  display: none;
}

[id='nav-toggle']:checked ~ .nav {
  left: 0;
  box-shadow:4px 0px 20px 0px rgba(0,0,0, 0.5);
  -moz-box-shadow:4px 0px 20px 0px rgba(0,0,0, 0.5);
  -webkit-box-shadow:4px 0px 20px 0px rgba(0,0,0, 0.5);
  overflow-y: auto;
}

[id='nav-toggle']:checked ~ main > article {
  -webkit-transform: translateX(150px);
  -moz-transform: translateX(150px);
  transform: translateX(150px);
}

[id='nav-toggle']:checked ~ .nav > .nav-toggle:after {
  content: '\2715';
}

.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 24px 0px auto;
  padding: 0;
  gap: 30px;
}

.header__menu-mobile {
  display: none;
}

.group_edit_menu{
  width: 654px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group_edit_block{
  border: 1px solid #E1E4ED;
  border-radius: 8px;
  background-color: #F9FAFB;
}

.content_group_edit_block{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
}

.group_edit_block:hover {
  border: 1px solid #5932EA;
}

.title_groupChange{
  font-weight: 400;
  font-size: 12px;
}

.background_svg_time_to_task_groupEdit{
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: #232360;
  border-radius: 1.5px;
}

.svg_time_to_task_groupEdit{
  width: 6px;
  height: 8px;
  margin: 4px 5px;
  background-image: url(../src/icons/svg/GroupEdit/timeToTaskGroupEdit.svg);
  background-repeat: no-repeat;
}

.svg_data_task_groupEdit{
  width: 16px;
  height: 18px;
  margin: auto;
  margin-right: 8px;
  background-image: url(../src/icons/svg/GroupEdit/calendar.svg);
}

.priority_span_groupEdit{
  width: 16px;
  height: 16px;
  margin: auto;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #232360;
}

.tags_span_groupEdit{
  width: 13px;
  height: 12px;
  margin: auto;
  margin-right: 8px;
  background-image: url(../src/icons/svg/GroupEdit/tagsGroupEdit.svg);
}

.svg_dopMenu_groupEdit{
  width: 18px;
  height: 5px;
  margin: auto;
  margin-right: 8px;
  background-image: url(../src/icons/svg/GroupEdit/dopMenu.svg);
}

.nav-menu__link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}

.main-page {
  margin: 30px 0px 0px 70px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 92vh;
}

.register-form__page {
  display: flex;
  gap: 16px;
  height: 92vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: #FFF;
}

.register-form__container{
  display: table;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  position: absolute;
  width: 400px;  
  left: calc(50% - 400px/2);
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
  border-radius: 10px;
}

.register-form__container-input {
  margin-bottom: 20px;
}

.register-form__header {
  font-weight: 600;
  font-size: 24px;
  color: #232360;
  line-height: 1;
  margin: 0;
}

.register-form__heading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #232360;
}

.register-form__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6B7280;
  margin-top: 20px;
}

.forgote-pas .register-form__container{
  width: 550px;
  left: calc(50% - 550px/2);
}

.forgot-password-sented-mail {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #232360;
  
  animation-name: fadeEmailSent;
  animation-duration: 3s;
  animation-fill-mode: both;
}

.forgot-password-sented-mail h2 {
  margin: 0;
}

.forgot-password-sented-mail-img {
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(../src/icons/svg/sented-mail.svg);
  background-repeat: no-repeat;
  animation-name: fadeEmailSent;
  animation-duration: 3s;
  animation-fill-mode: both;
}

@keyframes fadeEmailSent {
  0% {opacity: 0}
  100% {opacity: 1}
}

.reset_password .register-form__container{
  align-items: flex-start;
  justify-content: center;
  width: 750px;
  left: calc(50% - 750px/2);
}

.register-form__form {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

.register-from__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 24px;
  width: 100%;
  height: 100%;
} 

.reset_password_form{
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.password_successfuly{
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: fadeSuccess;
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes fadeSuccess {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.successfuly_onchange{
  margin-left: 25px;
  font-size: 24px;
  font-weight: 600;
  color: #232360;
}

.success-spinner {
  margin-top: 45px;
  text-align: center;
}

.success-lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.success-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8px;
  height: 18px;
  margin-top: -2px;
  border: 3px solid #232323;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #232323 transparent transparent transparent;
}
.success-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.success-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.success-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes success-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.check{
  display: block;
  width: 32.5px;
  height: 32.5px;
  background-image: url(/src/icons/svg/reset-pswd-accept.svg);
  background-repeat: no-repeat;
  background-position: center;

}

.reset_passwaord_description{
  width: 700px;
  height: 24px;
  font-style: normal;
  margin-bottom: 20px;
}

.res_pas_input_form__label {
  font-weight: 600;
  font-size: 12px;
  color: #6B7280;
  margin-top: 20px;
  margin-bottom: 12px;
}

.reset-form__input { 
  width: 338px;
  height: 40px; 
  border: solid 1px #c8cccc;
  border-radius: 5px;
  outline: none;
  background-color: #fffafa;
  color: #000000;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  resize: none;
  margin-bottom: 20px;
}

.register-form__input { 
  min-width: 100%; 
  border: solid 1px rgba(229, 231, 235, 1);
  border-radius: 5px;
  outline: none;
  background-color: #F9FAFB;
  color: #6B7280;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
  resize: none;  
}

.forgote-pas .register-form__input{
  margin: 20px;
}

.register-form__input:focus{
  border: 1px solid #546FFF;
  transition: border ease-in-out 0.3s;
}

.register-form__input::placeholder {
  color: #000000;
  opacity: 0.3;
}

.form-register_line{
  width: 224.5px;
  height: 0px;
  position: relative;
  top: 5px;
  background: #747676;
  opacity: 0.5;
  border: 1px solid #747676;
}

.form-reset_line{
  width: 700px;
  height: 1px;
  background: #E5E7EB;
  margin: 20px auto 0px auto;
}

.form-register_or{
  width: 31px;
  height: 20px;
  margin: 0 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #747676;

}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100vw;
  background: #FFF;
}

.btn_ {
  background-color: #3A3A3A;
  color: #ffffff;
}

.btn-complete-todo{
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;
  gap: 16px;
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border: none;
}

.todo-list__item:hover .btn-complete-todo{
  border: 2px solid #5932EA;
  border-radius: 4px; 
}

.btn-menu, .btn_ {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn-menu {
  padding: 0;
}

.btn-complete-todo__hov{
  display: none;  
  height: 18px;
  width: 18px;
}

.btn-complete-todo:hover{
  border: none;  
}

.btn-complete-todo:hover .btn-complete-todo__hov{
  position: absolute;
  margin: auto;
  right: 0px;
  left: -1px;
  border-radius: 4px;
  display: block;
  background-image: url(../src/icons/svg/SubtractTodos.svg);
  background-repeat: no-repeat;
  background-size: contain;
}


.user-avatar,
.user-avatar-mobile{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #232360;
  padding: 2px;
  object-fit: cover;
}

.user-avatar-mobile {
  background-color: #fff;
  width: 40px;
  height: 40px;
}

.menu {  
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  display: flex;
  transform: scale(0);
}

.menu.active{
  z-index: 100;
  transform: scale(1);
}

.menu-content{
  padding: 24px 0px;
  width: 219px;
  position: absolute;
  top: 45px;
  right: -200px;
  background-color: #FFFFFF;
  z-index: 100;
  box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
  border-radius: 10px; 
}

.menu, ul{
  padding: 0;
}

.a-list-menu {
  margin-left: 24px;
}

.a-item-menu {
  display: flex;
  margin-bottom: 25px;
}

.a-list-menu__person-info {
  max-width: 65%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  
}

.a-list-menu__person-data {
  display: flex;
  align-items: center;
  margin: 0px 0px 28px 24px;
}

.a-list-menu__person-name {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.a-list-menu__person-name p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #232360;
}

.a-list-menu__person-name p:last-child{
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.a-list-menu__person-post p {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 13px;
  font-weight: 500;
  color: #768396;
}

.a-menu{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #232360;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.a-menu:hover {
  color: #5932EA;
}

.a-item-menu:hover .a-menu {
  color: #5932EA;
  transition: color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-1 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-2 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-3 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-4 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-5 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-6 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-7 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-8 {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.a-item-menu:hover .bg_menu-9 {
  fill: #5932EA;
  background-color: #fff;
  transition: fill 0.2s ease-in-out;
}


.menu li{
  cursor: pointer;
}

.bg_menu-1, 
.bg_menu-2,
.bg_menu-3,
.bg_menu-4,
.bg_menu-5,
.bg_menu-6,
.bg_menu-7,
.bg_menu-8,
.bg_menu-9 {
  width: 20px;
  height: 18px;
  display: block;
  margin-right: 10px;
  background-color: #232360;
  mask-repeat: no-repeat;
  mask-size: 18px 18px;
  transition: background-color 0.2s ease-in-out;
}

.bg_menu-1 {
  mask-image: url(../src/icons/svg/menu_ic/user.svg);
  
}

.bg_menu-2 {
  mask-image: url(../src/icons/svg/menu_ic/filled.svg);
  
}

.bg_menu-3 {
  mask-image: url(../src/icons/svg/menu_ic/settings.svg);
}

.bg_menu-4 {
  mask-image: url(../src/icons/svg/menu_ic/circle.svg);
} 

.bg_menu-5 {
  mask-image: url(../src/icons/svg/menu_ic/trash.svg);
}

.bg_menu-6 {
  mask-image: url(../src/icons/svg/tagListToAdd_bg.svg);
}

.bg_menu-7 {
  mask-image: url(../src/icons/svg/menu_ic/star.svg);
}

.bg_menu-8 {
  cursor: pointer;
  mask-image: url(../src/icons/svg/menu_ic/log-out.svg);
}

.bg_menu-9 {
  background-color: #FFFFFF;
  width: 18px;
  height: 18px;
  fill: #232360;
}

.btn-logout {
  background-color: #9ab8ba;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.btn-add-task {
  width: 136px;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background: #5932EA;
  text-align: center;
  border-radius: 6px;
  border: none;
  transition: background 0.3s ease-in-out;
}

.btn-add-task:disabled {
  background: #AC98F6;
  cursor: not-allowed;
  transition: background 0.3s ease-in-out;
}

.register-form__btn{
  padding: 10px 25px;
  color: #FAFAFA;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: #5932EA;
  border-radius: 10px;
  border: none;
  transition: background 0.3s ease-in-out;
}

.register-form__btn_without_margin{
  padding: 10px 25px;
  color: #FAFAFA;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: #5932EA;
  border-radius: 10px;
  margin-top: 6px;
  border: none;
  transition: background 0.3s ease-in-out;
}

.register-form__btn_without_margin:disabled {
  pointer-events: none;
  background: #AC98F6;
}

.alertCounterLengthOfTitle {
  color: orange;
  font-size: 12px;
  margin-top: 10px;
}

.alertCounterLengthOfTitleModal {
  color: orange;
  font-size: 12px;
  margin-right: 300px;
}

.register-form__btn:disabled {
  background: #AC98F6;
  cursor: not-allowed;
  transition: background 0.3s ease-in-out;
}

.close-add-task-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77px;
  height: 32px;
  padding: 8px 16px;
  margin-right: 16px;
  color: #232360;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  border: 1px solid #D9DADE;
  border-radius: 6px;
  background-color: transparent;
  transition: border 0.2s ease-in-out;

}

.close-add-task-form:hover {
  border: 1px solid #5932EA;
  transition: border 0.2s ease-in-out;
}

.login-form__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  width: 99px;
  height: 40px;
  color:rgb(255, 255, 255);
  background: #5932EA;
  border-radius: 10px;
  border: none;
  transition: background 0.3s ease-in-out;
}

.login-form__btn:disabled {
  background: #AC98F6;
  cursor: not-allowed;
  transition: background 0.3s ease-in-out;
}

.login-description{
  color: #232360;
}

.login-description a{
  color: rgba(89, 50, 234, 1);
}


.reset_btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-addtask,
.btn-addclose {
  border-radius: 5px;
  height: 25px;
}

.btn-addclose {
  margin: 0px 0px 25px 15px
}

.ft {
  color: green;
}

.hd {
  color: red;
}

.md {
  color: yellow;
}

.nl {
  color: blue;
}

/* .todo-list {
  display: flex;
  justify-content: center;
} */

.todo-list__container {
  width: 870px;
  padding-top: 36px; 
}

.chk-box__done {
  margin: 8px 0px 0px 15px;
}

.indef-of-task {
  margin-top: 6px;
  margin-right: 10px;
}

.btn-change-task {
  height: 30px;
  margin-left: auto;
  cursor: pointer;
  border: none;
  background-color: #929292;
  border-radius: 5px;
}

.btn-remove-task {
  width: 21px;
  height: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;  
  transition: 0.5s;
  margin: auto;
  margin-right: 8px;
  fill: #A4A8B1;
}

.brn-restore-task {
  height: 18px;
  width: 17px;
  margin: auto;
  border: none;
  cursor: pointer;
  fill: #A4A8B1;
  background-color: transparent; 
  transition: 0.5s ;
}

.brn-restore-task:hover {
  fill: #5932EA;
}

.btn-remove-task:hover {
  fill: #5932EA;
}

.btn-change-task:hover {
  background-color: #525252;
}

.todo-list__list {
  margin: 0;
  padding: 0;
}


.todo-list__item {
  position: relative;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  line-height: auto;
  background-color: #FFFFFF;
  border-radius: 8px;
  user-select: none;
}

@keyframes scaleIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.96);
  }
}

.wrapper-todo {
  width: 100%;
  display: flex; 
  gap: 10px;
  align-items: center;
}

.wrapper-swiped-right {
  align-items: center;
  padding-left: 22px;
}

.wrapper-swiped-left {
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}

.wrapper-swiped-right__content,
.wrapper-swiped-left__content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.wrapper-swiped-right__description,
.wrapper-swiped-left__description {
  width: 250px;
  margin-left: 15px;
  color: #FFFFFF;
}

.wrapper-swiped-left__content {
  flex-direction: row-reverse;
}
.wrapper-swiped-left__description {
  margin-right: 15px;
  margin-left: 0;
  text-align: end;
}

.todo-list__item:hover {
  box-shadow: none;
  cursor: pointer;
  box-shadow: 2px 3px 3px rgba(96, 108, 128, 0.15);
  background-color: #FFF;
  overflow: visible;
}

.prority-bage-0,
.prority-bage-1,
.prority-bage-2,
.prority-bage-3 {
  position: absolute;
  left: 0;
  padding: 0;
  width: 8px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
}

.prority-bage-0 {
  background-color: #E1E4ED;
}

.prority-bage-1 {
  background-color: #F85640;
}

.prority-bage-2 {
  background-color: #F8C440;
}

.prority-bage-3 {
  background-color: #32EAA8;
}
.btn-complete-todo-container__mobile {
    margin-right: 7px;
    margin-left: 7px;
}
.todo-list__info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 10px;
  flex-direction: row;
}

.todo-list__date-info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.todo-list__date,
.todo-list__datetime,
.todo-list__tags {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  color: #94A3BD;
}

.todo-list__date {
  padding-left: 21px;
  gap: 2px;
  background-image: url(../src/icons/svg/TasksLiatCards/clock.svg);
  background-repeat: no-repeat;
  background-position: left center;
}

.todo-list__tags {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}

.todo-list__tags ul{
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  flex: none;
  gap: 10px;
}

.todo-list__tags-mobile {
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* overflow: hidden; */
  color: #94A3BD;
}

.todo-list__tags li {
  
}

.runTime-container{
  min-width: 112px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.renTime-container__time-counter{
  font-weight: 400;
  font-size: 12px;  
  color: #232360;
}

.span-count-time{
  font-weight: 600;
}

.runTime-container__time{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.runTime-container__percentTime,
.checkList-container__percent {
  min-width: 112px;
  height: 5px;
  background-color: #E1E4ED;
  border-radius: 6px;
  transition: width 0.3s ease-in-out;
}

.runTime-container__percentTime-complete{
  border-radius: 6px;
  min-width: 0%;
  max-width: 100%;
  height: 100%;
  background-color: #5932EA;
  transition: width 0.3s ease-in-out;
}

.restore_or_remove_todos{
  position: absolute;
  left: 99%;
  background-color: #ffffff;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 8px 8px 0;
  box-shadow: 2px 3px 3px rgba(96, 108, 128, 0.15);
}

.todo-list__item:hover .restore_or_remove_todos,
.wrapper-todo:hover .restore_or_remove_todos{
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
  width: 95px;
  height: 100%;
}

.todo-list__item__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
  padding: 16px 0px;
  height: auto;
  overflow: hidden;
}

.todo-list__item__container__title {
  padding: 0;
  word-wrap: break-word;
  font-size: 16px;
  line-height: normal;
  color: #232360;
}

.todo-list__item__container__description {
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #94A3BD;
}

.dedicated_task{
  border: 1px solid #5932EA;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;
  background-color: #F2EEFF;
}

.dedicated_task:hover{
  border: 1px solid #5932EA;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;
  background-color: #F2EEFF;
}

.dedicated_task .btn-complete-todo{
  opacity: 0;
}

.dedicated_task .restore_or_remove_todos{
  opacity: 0;
}

.todo-list__is-null {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 50px;
  color: #A4A8B1;
  font-size: 20px;
  line-height: 19px;
  font-weight: 500;
}

.main-container-view {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.page-title__hash{
  font-weight: 400;
  font-size: 30px;
  color: #232360;
  margin-right: 12px;
}



.completed-title-page{
  position: absolute;
  width: 33px;
  height: 33.2px;
  margin-right: 9px;
  background-image: url(../src/icons/svg/completed-title-page.svg);
}

.page-date{
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
}



.button-add-tags{
  display: flex;
  flex-direction: row;
  all: unset;
  background-color: #5932EA;
  color: #fff;
  font-weight: 600;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 50px;
  border-radius: 6px;
  transition: background 0.3s ease-in-out;
}

.button-add-tags:disabled {
  background-color: #AC98F6;
  cursor: not-allowed;
  transition: background 0.3s ease-in-out;
}

.plus-add{
  width: 16px;
  height: 16px;
  margin: auto;
  margin-right: 8px;
  background-image: url(../src/icons/svg/Union.svg);
}



.button-close-add-tags{
  all: unset;
  border: 1px solid #d4d6db;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 6px;
  margin-bottom: 50px;
}

.loader-container {
  
  display: flex;
  justify-content: center;
  align-items: center;  
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #a1a0a0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #C6C7F8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submenu-mobile {
  display: none;
}

.submenu__container {
  width: 314px;
  min-height: 92vh;
  background-color: #FFFFFF;
  border-right: 1px solid #E8E8E8;
}

.submenu {
  display: flex;
  flex-direction: column;
}

.submenu__list {
  padding: 0px 0px 0px 0px;
  margin: 0;
}

.submenu__item {
  display: flex;
  align-items: center;
  width: 314px;
  height: 62px;
}

.submenu__item:hover {
  background-image: url(../src/icons/svg/submenu_ic/triangle.png);
  background-repeat: no-repeat;
  background-position:  left center;
}

.submenu__item:hover a {
  color: #5932EA;
}

.submenu__item:hover .submenu__item-icon-1{
  background-color: #5932EA;
}

.submenu__item:hover .submenu__item-icon-2{
  background-color: #5932EA;
}

.submenu__item:hover .submenu__item-icon-3{
  background-color: #5932EA;
}

.submenu__link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: #232360;
}

.submenu__link-active {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: #5932EA;
}

.submenu__item-active {
  display: flex;
  align-items: center;
  width: 314px;
  height: 62px;
  background-image: url(../src/icons/svg/submenu_ic/triangle.png);
  background-repeat: no-repeat;
  background-position:  left center;
}

.submenu__item-active .submenu__item-icon-1 {
  background-color: #5932EA;  
}

.submenu__item-active .submenu__item-icon-2 {
  background-color: #5932EA;  
}

.submenu__item-active .submenu__item-icon-3 {
  background-color: #5932EA;  
}

.submenu__item-icon-1 {
  background-color: #5932EA;
  mask-image:url(../src/icons/svg/submenu_ic/element-1.svg);
}

.submenu__item-icon-2 {
  background-color: #5932EA;
  mask-image: url(../src/icons/svg/submenu_ic/calendar.svg);
}

.submenu__item-icon-3 {
  background-color: #5932EA;
  mask-image: url(../src/icons/svg/submenu_ic/filled.svg);
}

.submenu__item-icon-1,
.submenu__item-icon-2,
.submenu__item-icon-3 {
  display: block;
  width: 28px;
  height: 28px;
  margin: 0;
  padding: 0;
  margin-left: 40px;
  margin-right: 20px;
  background-color: #232360;
  mask-size: 28px 28px;
  mask-repeat: no-repeat;
}

.submenu__time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 260px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: #ffffff;
}

.submenu__time__item {
  font-size: 34px;
  font-weight: 700;
  color: #000;
}

.submenu__time__description {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.overlay {
  background-color: #0b1516;
  width: 100px;
  height: 100%;
}

.url-header__title-1,
.url-header__title-2,
.url-header__title-3,
.url-header__title-4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5932EA;
  padding-left: 19px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: left center;
}

.url-header__title-1 {
  background-image:url(../src/icons/svg/submenu_ic/element-1-active.svg);
}

.url-header__title-2 {
  background-image:url(../src/icons/svg/submenu_ic/filled-active.svg);
  background-size: 17px;
}

.url-header__title-3 {
  background-image:url(../src/icons/svg/completed-title-page.svg);
}

.url-header__title-4 {
  background-image:url(../src/icons/svg/trash-hover.svg);
}

.modal-header__change-task {
  height: 42px;
  background-color: #F5F5F5;
  border-bottom: 1px solid #E5E7EB;
}

.modal-header__change-task {
  display: flex;
  align-items: center;
}

.modal-body__change-task {
  padding: 0;
  background-color: #F5F5F5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.hide-btn {
  cursor: pointer;
}

.hideBtnComponent {
  stroke: #9F8CFF;
}

.taskKebabComponent {
  fill: #9F8CFF;
}

.hide-btn:hover .hideBtnComponent {
  stroke: #5932EA;
}

.task-menu-btn {
  cursor: pointer;
  margin-right: 24px;
}

.task-menu-btn:hover  .taskKebabComponent {
  fill: #5932EA;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 957px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0px 32px 0px 32px;
  justify-content: space-between;
}

.change-data__line {
  width: auto;
  height: 1px;
  margin: 0px 32px 0px 32px;
  background-color: #E5E7EB;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  border: none;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  margin-top: 48px;
  border-top: 1px solid #E5E7EB;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

@keyframes fade {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.checklistToComplete {
	width: 28px;
	height: 28px;
	background: #80f284;
	margin: 20px auto;

	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	position: relative;
}

.checklistToComplete label {
	cursor: pointer;
	position: absolute;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	left: 4px;
	top: 4px;
	background: #ffffff;
}

.checklistToComplete label:after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	background: #80f284;

	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	top: 2px;
	left: 2px;

}

.checklistToComplete label:hover::after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=30);
	opacity: 0.2;
}

.checklistToComplete input[type=checkbox]:checked + label:after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

.modal-form__container {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: center;
}
.description-task-container {
  max-width: 705px;
  display: flex;
  flex-direction: column;
}

.priority-container {
  width: 252px;
  min-height: 608px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  background: #FFFFFF;
  border-right: 1px solid #E8E8E8;
  box-shadow: 0px 0px 50px rgba(220, 224, 249, 0.5);
  border-bottom-right-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.priority-time {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  margin-bottom: 34px;
  gap: 8px;
}

.timer-info-container {
  margin-left: 4px;
}

.priority-time__container {
  display: flex;
}

.priority-time__input {
  width: 50px;
  border: none;
  outline: none;
  background-color: #fff;
}

.priority-time__input::-webkit-inner-spin-button,
.priority-time__input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.priority-list{
  margin: 0;
  padding: 0;
}

.priority-item{
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 28px;
}

.priority-item:first-child {
  margin: 0;
}

.priority-item__container {
  width: 204px;
  height: 234px;
  position: absolute;
  top: 0px;
  right: 10px;
}

.priority-input__add-time {
  max-width: 25px;
  border: none;
  outline: none;
  background-color: transparent;
}

.add-task__change-group__data {
  width: 120px;
  margin-right: 18px;
  justify-content: space-between;
}

.add-task__change-group__time {
  width: 114px;
}

.add-task__change-group__data,
.add-task__change-group__time {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 6px 7px;
  border: 1px solid #E1E4ED;
  border-radius: 6px;
  background-color: #F9FAFB;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.add-task__change-group__data:hover .priority__input-date::placeholder,
.add-task__change-group__time:hover .priority__input-time::placeholder {
  color: #232360;
  transition: all 0.2s ease-in-out;
}

.add-task__change-group__data:hover,
.add-task__change-group__time:hover {
  border: 1px solid #5932EA;
  transition: all 0.2s ease-in-out;
}

.add-task__change-group__data:hover .add-task__calendar-svg {
  stroke: #5932EA;
  transition: all 0.2s ease-in-out;
}

.add-task__change-group__time:hover .add-task__time-svg {
  fill: #5932EA;
  transition: all 0.2s ease-in-out;
}

.add-task__change-group__data:active,
.add-task__change-group__time:active {
  border: 1px solid #5932EA;
  transition: all 0.2s ease-in-out;
}

.add-task__calendar-svg {
  width: 12px;
  height: 12px;
  stroke: #A4A8B1;
  transition: all 0.2s ease-in-out;
}

.add-task__time-svg {
  fill: #A4A8B1;
  transition: all 0.2s ease-in-out;
}


.priority-input,
.priority__input-date,
.priority__input-time {
  color: #A4A8B1;
}

.priority-input,
.priority__input-date,
.priority__input-time {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.priority__input-date {
  width: 88px;
  padding: 0;
  line-height: 1;
}

.priority__input-time {
  width: 86px;
  padding: 0;
  line-height: 1;
}
.priority__input-date::placeholder,
.priority__input-time::placeholder {
  color: #A4A8B1;
  transition: all 0.2s ease-in-out;
}

.priority-input {
  appearance: none;
}

.priority-span-addTask-0,
.priority-span-addTask-1,
.priority-span-addTask-2,
.priority-span-addTask-3 {
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 7px;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.priority-span-addTask-0:hover,
.priority-span-addTask-1:hover,
.priority-span-addTask-2:hover,
.priority-span-addTask-3:hover {
  background-color: #5932EA;
}

.priority-span-addTask-0 {
  background-color: #E1E4ED;
  transition: background-color 0.2s ease-in-out;
}

.priority-span-addTask-1 {
  background-color: #F85640;
  transition: background-color 0.2s ease-in-out;
}

.priority-span-addTask-2 {
  background-color: #F8C440;
  transition: background-color 0.2s ease-in-out;
}

.priority-span-addTask-3 {
  background-color: #32EAA8;
  transition: background-color 0.2s ease-in-out;
}

.modal-label {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20.4px;
  color: #232360;
  margin-bottom: 13px;
}

.prioryty_group{
  margin: 0 10px 0 15px;
  color: white;
}

.md-header-ft {
  background-color: green;
}
.md-header-hd {
  background-color: red;
}
.md-header-md {
  background-color: yellow;
}
.md-header-nl {
  background-color: blue;
}

.hideBtn-controls-to-modal {
  display: none;
}

.unhideBtn-controls-to-modal {
  display: flex;
}

.hideBtn-controls-to-modal,
.unhideBtn-controls-to-modal {
  max-width: 622px;
  margin-left: 28px;
  align-items: center;
  margin-right: 55px;
  justify-content: flex-end;
}

.cancel-changes-form,
.save-changes-form {
  height: 32px;
  border-radius: 6px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  border: none;
  outline: none;
}

.cancel-changes-form {
  border: 1px solid #D9DADE;
  box-sizing: border-box;
  background-color: transparent;
  margin-right: 8px;
}

.cancel-changes-form:hover {
  border: 1px solid #5932EA;
}

.save-changes-form {
  border: 1px solid transparent;
  background-color: #5932EA;
  color: #FFFFFF;
  font-weight: 600;
  transition: background 0.3s ease-in-out;
}

.save-changes-form:disabled {
  background-color: #AC98F6;
  cursor: not-allowed;
  transition: background 0.3s ease-in-out;
}

.modal-form__input-container__focus,
.modal-form__input-container {
  display: flex;
  flex-direction: column;
  max-width: 622px;
  margin-left: 28px;
  margin-top: 21px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}


.modal-form__input-container__focus {
  border: 1px solid #5932EA;
  background-color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}

.modal-form__input-title:empty::before,
.modal-form__input-descr:empty::before {
  content: attr(data-placeholder);
  font-size: 14px;
  color: #A4A8B1;
  display: block;
  pointer-events: none;
}

.modal-form__input-title,
.modal-form__input-title__onblur {
  max-width: 606px;
  height: auto;
  outline: none;
  margin-top: 8px;
  margin-left: 8px;
  padding: 0;
  color: #232360;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  border: none;
  resize: none;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  background-color: transparent;
}

.modal-form__input-descr,
.modal-form__input-descr__onblur {
  max-width: 606px;
  height: auto;
  outline: none;
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  padding: 0;
  color: #6B7280;
  font-size: 14px;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid transparent;
  resize: none;
  box-sizing: border-box;
  background-color: transparent;
}

.footer-separator {
  width: 650px;
  height: 1px;
  margin-right: 100px;
  margin-top: 12px;
  background-color: #E5E7EB;
}

.modal-form__input-title__onblur,
.modal-form__input-descr__onblur { 
  transition: border, color 0.3s ease-in-out;
}

.modal-form__input-title:focus,
.modal-form__input-descr:focus {
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid transparent
}

.modal-form__input::placeholder {
  color: #0b1516;
}

.checkList-container {
  max-width: 614px;
  margin: 0;
}

.checklist-container {
  padding-left: 36px;

}

.checklist-box__info {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 4px;
}

.checklist-box__title {
  font-family: 'OpenSans';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #232360;
}

.checklist-box__list-arrow-v2 {
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #5932EA;
  border-bottom: 2px solid #5932EA;
  transform: rotate(-45deg);
}

.checklist-box__list-arrow {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-top: -5px;
  cursor: pointer;
  border-right: 2px solid #A4A8B1;
  border-bottom: 2px solid #A4A8B1;
  transform: rotate(45deg);
  transition: border 0.3 ease-in-out;
}

.active-btn {
  border-right: 2px solid #5932EA;
  border-bottom: 2px solid #5932EA;
}

.checklist-box__list-arrow:hover {
  border-right: 2px solid #5932EA;
  border-bottom: 2px solid #5932EA;
  transition: border 0.3 ease-in-out;
}

.checklist-box__control-btns {
  position: absolute;
  left: 55px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 156px;
  padding: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
  border-radius: 10px;
  z-index: 1;
}

.checklist-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.checklist-item:hover .checklist-completed-btn,
.checklist-item:hover .checklist-remove-btn {
  color: #5932EA;
}


.checklist-item:hover .checklist-completed-btn__bg,
.checklist-item:hover .checklist-remove-btn__bg {
  background-color: #5932EA;
}

.checklist-completed-btn,
.checklist-remove-btn {
  height: 16px;
  text-align: start;
  outline: none;
  border: none;
  padding: 0;
  padding-left: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #232360;
  background-color: transparent;
  transition: color 0.1s ease-in-out;
}

.checklist-completed-btn__bg,
.checklist-remove-btn__bg {
  display: block;
  width: 16px;
  height: 16px;
  mask-size: 16px 16px;
  mask-repeat: no-repeat;
  background-color: #232360;
}

.checklist-completed-btn__bg {
  mask-image:url(../src/icons/svg/menu_ic/circle.svg);
}

.checklist-remove-btn__bg {
  mask-image:url(../src/icons/svg/menu_ic/trash.svg);
}

.checkList{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  padding-right: 6px;
  padding-left: 5px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #6B7280;
  transition: background-color 0.3s ease-in-out;
}

.checkList span {
  height: 13.5px;
  width: 13.5px;
  margin-right: 9px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color:transparent;
  border-radius: 3px;
  transition: border 0.3s ease-in-out;
}

.checkList span:hover {
  border: 1px solid  #5932EA;
  transition: border 0.3s ease-in-out;
}

.checkList [type=checkbox]:checked + span:before {
  width: 14px;
  height: 14px;
  content: '0';
  top: -1px;
  right: -1px;
  position: absolute;
  color: transparent;
  background-image: url(../src/icons/svg/Subtract.svg);
  background-repeat: no-repeat;
}

.checkList [type=checkbox]:checked + span:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkList:last-child {
  margin: 0;
}

.checkList:hover {
  background-color: #F2EEFF;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.checkList:hover .checkList_content__rm-btn-container {
  display: flex;
}

.checkList_content{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.checkList_content__rm-btn-container {
  display: none;
  align-items: center;
}

.checkList_content__rm-chklst {
  width: 15px;
  height: 16px;
  border: none;
  outline: none;
  background-color: #A4A8B1;
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;
  mask-image: url(../src/icons/svg/menu_ic/trash.svg);
  transition: background-color 0.2s ease-in-out;
}

.checkList_content__rm-chklst:hover {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.addchekclist {
  width: 109px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: transparent;
  font-weight: 400;
  color: #A4A8B1;
  background-image: url(../src/icons/svg/CalendarDDSelect/Union-item.svg);
  background-position: center;
  transition: color, background 0.1s ease-in-out;
}

.addchekclist,
.addCheckElement__add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  border: none;
  border-radius: 6px;
  outline: none;
  margin-left: 5px;
  padding-left: 20px;
  line-height: 1;
  font-size: 10px;
  background-repeat: no-repeat;
  background-position: 6px center;
}

.addCheckElement__close-btn {
  height: 27px;
  margin: 0;
  font-size: 10px;
  line-height: 13px;
}

.addchekclist:hover {
  background-color: #5932EA;
  color: #ffffff;
  background-image: url(../src/icons/svg/CalendarDDSelect/Union-item_hover.svg);
  transition: color, background 0.1s ease-in-out;
}

.addCheckElement {
  display: flex;
  align-items: center;
  width: 207px;
  min-width: 207px;
  max-width: 610px;
  height: 25px;
  margin-top: 10px;
  margin-left: 5px;
  padding: 4px 12px;
  color: #A4A8B1;
  font-size: 12px;
  line-height: 13;
  font-weight: 400;
  border: 1px solid #5932EA;
  border-radius: 6px;
}

.addCheckElement__input {
  display: block;
  line-height: 25px;
  resize: none;
  overflow: hidden;
  min-width: 190px;
  max-width: 600px;
  height: 25px;
  padding: 0;
  padding-right: 4px;
  outline: none;
  background-color: transparent;
  border: none;
  caret-color: #5932EA;
}

.addCheckElement__input:focus .addCheckElement{
  background-color: #5932EA;
}

.addCheckElement__add-btn {
  width: 78px;
  margin: 0;
  font-weight: 600;
  background-color: #5932EA;
  color: #FFFFFF;
  background-image: url(../src/icons/svg/CalendarDDSelect/Union-item_hover.svg);
  transition: color, background 0.3s ease-in-out;
}

.addCheckElement__cancel-btn:disabled {
  background-color: #AC98F6;
  transition: background 0.3s ease-in-out;
}

.addchecklist__control-btns {
  display: flex;
  width: 160px;
  margin-left: 5px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.editCheckElement {
  display: block;
  width: 558px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #6B7280;
}

.btn_edit_cheklist{
 margin: 0 5px;
}

.label_to_checkBox {
  display: flex;
  margin-bottom: auto;
  margin-top: 5px;
}

.checkList_checkbox{
  display: none;
  cursor: pointer;
  margin-right: 8px;
}

.checkList_completed{
  text-decoration: line-through;
  color: #6B7280;
}

.checkList_completed,
.checkList_uncompleted {
  width: 558px;
  word-wrap: break-word;
  cursor: pointer;
}

.addchecklist{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.addchecklist_btn{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.addchecklist_btn :last-child{
  margin-left: 15px;
}

.btn_ {
  outline: none;
  border: none;
  background-color: transparent;
  background-color: #3A3A3A;
  color: #ffffff;
  cursor: pointer;
}

.hide {
  display: none;
}

.unhide{
  display: flex;
  margin-right: 23px;
  justify-content: flex-end;
}

.settings-page__container {
  width: 100%;
  background-color: #F8FAFB;
  padding: 24px 56px 0px 56px;
}

.settings-page__background {
  display: block;
  width: auto;
  height: 280px;
  background-image: url(../src/icons/png/bg-profile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

.settings-page__avatar-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 137px;
  border-radius: 100%;
  background-color: #FFFFFF;
  margin: 234px 0px 0px 50px; 
  cursor: pointer;
}

.avatar {
  display: none;
  position: absolute;
  width: 115px;
  height: 115px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  background: rgba(35, 35, 96, 0.90);
}

.settings-page__avatar-container:hover .settings-page__user-avatar{
  border: 3px dashed #787486;
  padding: 2px;
}

.settings-page__avatar-container:hover .avatar {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settings-page__user-avatar {
  display: block;
  width: 125px;
  height: 125px;
  border-radius: 100%;
  object-fit: cover;
}

.avatar-text {
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.settings-page__header {
  display: flex;
  margin: 20.5px 0px 0px 238px;
  align-items: center;
  justify-content: space-between;
}

.settings-page__header h1{
  font-size: 30px;
}

.settings-page__title {
  font-style: 30px;
  font-weight: 700;
  line-height: 38px;
  color: #232360;
}

.settings-page__btn-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.settings-page__btn-close,
.settings-page__btn-save {
  height: 40px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
}

.settings-page__btn-close {
  width: 107px;
  border: 1px solid #d9dade;
  
}
.settings-page__link-close {
  font-weight: 500;
  color: #232360;
  text-decoration: none;
}

.settings-page__btn-save {
  width: 140px;
  background-color: #5932EA;
  font-weight: 600;
  color: #ffffff;
  margin-left: 15px;
  transition: linear background-color 0.3s;
}

.settings-page__nav-menu {
  margin: 60px 0px 24px 76px
}

.main-settings-page__container {
  max-width: 800px;
  margin: 0px 21px 0px 76px;
}

.main-settings-page__list {
  margin: 0;
  padding: 0;
}

.main-settings-page__item {
  margin-bottom: 24px;
  border-bottom: 1px solid #EEEEF4;
}

.main-settings-page__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}


.main-settings-page__item-container {
 display: flex;
 justify-content: space-between;
 
}

.main-settings-page__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #768396;
  margin-bottom: 24px;
}

.main-settings-page__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #232360;
  margin-bottom: 10px;
}

.main-settings-page__toogle {
  margin-left: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5932EA;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5932EA;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.settings-profile {
  margin: 0;
  padding: 0;
  margin: 0px 0px 0px 79px;
}

.settings-profile__item {
  position: relative;
}

.settings-profile__item,
.settings-profile__item-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #232360;
  margin-bottom: 24px;
}

.settings-profile__item,
.settings-profile__item-name {
  width: 724px;
  border-bottom: 1px solid #EEEEF4;
}

.settings-profile__item-name {
  display: flex;
  justify-content: space-between;
}

.settings-profile__title {
  padding-bottom: 5px;
}

.settings-profile__item:last-child {
  border-bottom: none;
}

.settings-profile__item-description {
  color: #FFA500;
  position: absolute;
  top: 71px;
  left: 0px;
  animation-name: fade;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.settings-profile__name {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #232360;
}

.settings-profile-name-container,
.settings-profile-lastname-container {
  position: relative;
}

.settings-profile__other {
  width: 492px;
}

.settings-profile__name {
  width: 308px
}

.settings-profile__other,
.settings-profile__name {
  height: 45px;
  border-radius: 9px;
  border: 1px solid #E5E5ED;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #768396;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 24px;
  transition: border ease-in-out 0.3s;
}

.settings-profile__other:focus,
.settings-profile__name:focus,
.settings-profile__datebirth:focus {
  border: 1px solid #546FFF;
  transition: border ease-in-out 0.3s;
}

.settings-profile__datebirth-control-list {
  display: flex;
  margin: 0;
  padding: 0;
}

.settings-profile__datebirth-control-item {
  margin-right: 10px;
}

.settings-profile__datebirth-control-item:last-child {
  margin-right: 0;
}

.settings-profile__datebirth {
  display: flex;
}

.settings-profile__datebirth__mobile {
  display: none;
}

.settings-profile__datebirth,
.settings-profile__datebirth__mobile {
  align-items: center;
  height: 44px;
  border: 1px solid rgba(225, 228, 237, 1);
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  line-height: 19px;
  color: rgba(107, 114, 128, 1);
  outline: none;
  transition: border ease-in-out 0.3s;
  cursor: pointer;
  margin-bottom: 24px;
}

.date-of-birth-day {
  width: 93px;
}

.date-of-birth-month {
  width: 134px;
}

.date-of-birth-year {
  width: 109px;
}

.settings-profile__datebirth-input {
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  color: rgba(107, 114, 128, 1);
  cursor: pointer;
}

.settings-profile__datebirth-arrow {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../src/icons/svg/settings_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  animation-name: rotateArrowBack;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.datebirth-arrow__active {
  animation-name: rotateArrowForward;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.settings-profile__datebirth-list {
  width: 100%;
  margin-top: -14px;
  height: 150px;
  overflow-y: scroll;
  font-size: 12px;
}

.settings-profile__datebirth-list::-webkit-scrollbar {
  width: 0;
}

@keyframes rotateArrowForward {
  0% {transform: rotateX(0deg);}
  100% {transform: rotateX(180deg);}
}

@keyframes rotateArrowBack {
  0% {transform: rotateX(180deg);}
  100% {transform: rotateX(0deg);}
}

.change-data__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


}

.change-data__input {
  margin: 0;
  width: 338px;
}

.ModalWindowEditEmail {
  width: 764px;
  height: 456px;
}

.modal_body {
  padding: 0px 32px 0px 32px;
}

.change-data__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #232360;
  padding: 42px 0px 0px 0px;
}

.change-data__description {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6B7280;
  padding: 16px 0px 26px 0px;
}

.change-data__description b {
  font-weight: 600;
}

.settings-profile__change-birth {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  z-index: 999;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
  border-radius: 10px;  
  top: -250px;
  right: -120px;
 
}

.change-data__box__title {
  font-weight: 400;
  font-size: 12px;
  color: #6B7280;
  padding-bottom: 10px;
  margin-top: 32px;
}

.error-change-email {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 10px;
  color: #F85640;
  transition: color 0.2s ease-in-out;
}

.unhide-error-email {
  transition: disaplay 0.2s ease-in-out;
}

.unhide-error-paswword {
  display: none;
  transition: display 0.2s ease-in-out;
}

.task-actions-container {
  display: flex;
  margin-bottom: 37px;
}

.to_the_end{
  justify-content: flex-end;
}

.stretch{
  justify-content: space-between;
}

.filter-container{
  position: relative;
}

.filter-button {
  position:sticky;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 115px;
  height: 40px;
  padding: 0px 7.2px;
  font-weight: 500;
  font-size: 15px;
  color: #232360;
  background: #FFFFFF;
  border: 1px solid #EBEEF2;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;
  cursor: pointer;
}
.filter-task {
  display: block;
}

.filter-task-mobile {
  display: none;
}

.add-task__container {
  max-width: 745px; 
  margin-top: -77px;
  margin-bottom: 37px;  
  border: 1px solid #E8E8E8;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.add-task__form {
  width: auto;
}

.add-task__change-group {
  display: flex;
  align-items: center;
}

.add-task__change-group__data,
.add-task__change-group__time,
.add-task__change-group__priority {
  position: relative;
}

.add-task__change-group__priority {
  height: 30px;
}

.add-task__input {
  width: 100%;
  height: auto;
  min-height: 16px;
  word-break: break-all;
  border: none;  
  outline: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1px;
  color: #A4A8B1;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  word-wrap: break-word;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  box-sizing:border-box;
}



.input-select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  margin: 0;
  color: #232360;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  box-sizing:border-box;
  appearance: none;
  cursor: pointer;
}

.input-time-zone {
  border: 1px solid #EBEEF2;  
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 8px;
  height: 39px;
  align-items: center;
}

.input-select-date {
  display: flex;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  line-height: 19px;
  color: rgba(107, 114, 128, 1);
  appearance: none;
  outline: none;
  border: none;
}

.add-timezone {
  text-align: center;
  padding-right: 4px;
}



.add-task__input:focus {
  /* border-bottom: 1px solid transparent; */
  box-sizing: border-box;

}

.tagsList-list {
  max-width: 713px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
}

.tagsList-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2EEFF;
  margin-right: 16px;
  padding: 6px 8px;
  margin-top: 16px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 13.62px;
  color: #5932EA;
}

.tagsList-item__bg {
  display: block;
  width: 9.75px;
  height: 9px;
  background-image: url(../src/icons/svg/tagListToAdd_bg.svg);
  margin-right: 3px;
}

.tagsList-item__container {
  display: flex;
  align-items: center;
}

.tagsList-item__name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.tagsList-item__remove-item {
  fill: #A4A8B1;
  cursor: pointer;
}
.tagsList-item__remove-item:hover {
  fill: #232360;
}

.page_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-task__add-tags-to-task {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-top: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  background-color: #A4A8B1;
  mask-size: contain;
  mask-image: url(../src/icons/svg/grid-add-task.svg);
  transition: background-color 0.2s ease-in-out;
}

.add-task__add-tags-to-task:hover {
  background-color: #5932EA;
  transition: background-color 0.2s ease-in-out;
}

.add-task__input-title {
  width: 100%;
  height: 100%;
  line-height: 28.6px;
  font-weight: 600;
  font-size: 14px;
  color: #232360;
  margin-bottom: 7px;
}

.add-task__input-title:empty::before,
.add-task__input-description:empty::before {
  content: attr(data-placeholder);
  color: #A4A8B1;
  display: block;
  pointer-events: none;
}

.MatchedText {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  border-radius: 8px;
  border: 1px solid #5932EA;
  color: #5932EA;
  padding: 5px 8px;
  background-color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;
}

.MatchedCloseBtn {
  width: 7px;
  height: 7px;
  cursor: pointer;
  background-image: url('/src/SmartTextRecognition/static/union.svg');
  background-size: cover;
}

.separator-add-task {
  max-width: 713px;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #E5E7EB;
  opacity: 0.1;
}

.addTaskBtn {
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  margin: 0;
  margin-right: 10px;
  padding: 7.2px;
  border: 1px solid #EBEEF2;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;
  cursor: pointer;
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}

.addTaskBtn__hide {
  display: none;
  transition: all 0.3s ease-in-out;
}

.addTaskPlus {
  display: block;
}

.addTaskPlus-Mobile {
  display: none;
}

.openDiv {
  width: 745px;
  height: 124px;
  margin-left: auto;
  border: 1px solid #EBEEF2;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;
  background-color: #FFFFFF;
  transition: all 0.3s ease-in-out;

}

.getcurrenttz-btn {
  border: 1px solid #6A7181;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  margin-left: auto;
}



.alertHide {
  visibility: hidden;
}

.alertUnhide {
  visibility: visible;
}

.success-alert-hide {
  display: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.success-alert-unhide {
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

.alert {
  margin: 0;
}

.settings-page__btn-save:disabled {
  cursor: not-allowed;
  background-color: rgb(35%, 20%, 92%, 0.5);
  transition: linear background-color 0.3s;
}

.popoverDate,
.popoverPriority,
.popoverTime {
  position: absolute;
}

.popoverDate {
  top: 38.5%;
  right: 53%;
}

.popoverTime {
  top: 0px;
  right: 0px;
}

.popoverPriority {
  top: 300px;
  right: 330px;
}

.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.avatar:hover {
  display: block;
}

.add-task__change-group__tags,
.add-task__change-group__priority {
  margin-left: 21px;
}

.add-task__change-group__priority p {
    display: none;
}

.add-task__change-group__tags-description {
  display: none;
}

@media (max-width: 576px)  {
  .header__buttons {
    display: none;
  }
  .login-container {
    height: 95vh;
  }
  .menu {
    position: relative;
  }
  .submenu__container {
    display: none;
  }
  .addTaskBtn {
    width: 32px;
    height: 32px;
    padding: 5.2px;
  }
  .addTaskPlus {
    display: none;
  }
  .addTaskPlus-Mobile {
    display: block;
    width: 20px;
    height: 20px;
  }
  .filter-button {
    width: 93px;
    height: 32px;
    padding: 5.2px;
    font-size: 12px;
  }
  .filter-task {
    display: none;
  }
  
  .filter-task-mobile {
    display: block;
    width: 19px;
    height: 19px;
  }
  .todo-list__container  {
    width: 100%;
    padding-top: 0;
  }

  .btn-complete-todo {
    display: block;
    border: 2px solid #5932EA;
    border-radius: 4px;
    width: 18px;
    height: 18px;
  }
  .btn-complete-todo__hov {
    width: 18px;
    height: 18px;
  }
  .btn-complete-todo:hover .btn-complete-todo__hov {
    right: 0px;
    left: -1.5px;
    top: -2px;
    background-repeat: no-repeat;
    background-size: 18px;
  }
  .btn-complete-todo-container__mobile {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .runTime-container {
    margin-right: 10px;
  }
  .settings-page__background {
    height: 65px;
    border-radius: 12px;

    margin: 0 4px;
  }
  .modal-header {
    padding: 12px 26px;
  }
  .modal-header__change-task,
  .modal-body__change-task {
    background-color: #FFFFFF;
  }
  .modal-header__change-task {
    border-bottom: 1px solid #E1E4ED;
  }
  .wrapper-todo {
    border-radius: 8px;
    justify-content: flex-start;
  }
  .wrapper-todo:hover .restore_or_remove_todos {
    display: none;
  }
  .todo-list__item__container {
    gap: 4px;
    max-width: 303px;
    width: 100%;
    padding: 8px 10px 8px 0px;
  }
  .todo-list__item {
    padding-right: 0;
    margin-bottom: 12px;
  }
  .todo-list__item__container__title {
    font-size: 12px;
  }
  .todo-list__item__container__description {
    font-size: 10px;
  }
  .todo-list__item__container__title,
  .todo-list__item__container__description {
    line-height: 16px;
  }
  .todo-list__is-null {
    text-align: center;
    line-height: 28px;
  }

  .todo-list__info-container {
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(90deg, #94a3bd 70.5%, #94a3bd00);
    background-clip: text;
    
  }
  .todo-list__tags {
    background: linear-gradient(90deg, #94A3BD 70.5%, rgba(148, 163, 189, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .todo-list__tags-mobile-ul {
    display: flex;
    flex: none;
    flex-wrap: wrap;
    max-height: 48px;
    gap: 12px;
    font-size: 10px;
  }

  .todo-list__tags-mobile-margin {
    margin-top: 0px;
  }

  .add-task__container {
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 12px;
    margin-top: -69px;
    margin-bottom: 12px;
  }
  .add-task__add-tags-to-task {
    width: 25px;
    height: 25px;
    mask-size: contain;
  }
  .separator-add-task {
    width: 100%;
    display: block;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .add-task__change-group {
    overflow: overlay;
  }
  .add-task__change-group::-webkit-scrollbar {
    height: 0;
  }

  .header {
    height: 6vh;
  }

  .header__menu {
    width: 100%;
    margin: 0px auto;
  }

  .a-list-menu__person-name p {
    color: #FFFFFF;
  }

  .a-list-menu__person-post p {
    color: #AC98F6;
  }

  .register-form__container {
    position: absolute;
    width: 390px;
    background-color: transparent;
    box-shadow: none;
  }

  .register-form__heading {
    margin-bottom: 30px;
  }

  .register-form__btn,
  .login-description {
    margin-top: 16px;
  }

  .forgote-pas .register-form__container{
    width: auto;
    left: 0;
  }

  .register-form__description {
    text-align: center;
  }
  .register-form__page {
    flex-direction: column-reverse;
  }
  .forgot-password-sented-mail {
    height: 150px;
  }

  .main-container-view {
    flex-direction: column;
  }

  .submenu-mobile {
    display: block;
  }

  .modal-content {
    min-height: 550px;
  }

  .modal-form__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .description-task-container {
    width: 100%;
    
  }

  .modal-form__input-container__focus,
  .modal-form__input-container {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .modal-form__input-container {
    margin: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-form__input-title,
  .modal-form__input-title__onblur {
    width: 300px;
    font-size: 16px;
  }
  .modal-form__input-descr,
  .modal-form__input-descr__onblur {
    width: 300px;
    margin-top: 0;
    font-size: 12px;
  }
  .hideBtn-controls-to-modal {
    display: none;
  }
  .unhideBtn-controls-to-modal {
    display: flex;
  }
  .cancel-changes-form {
    color: #232360;
  }
  .hideBtn-controls-to-modal,
  .unhideBtn-controls-to-modal{
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 12px;
    width: 330px;
  }
  .footer-separator {
    width: 100%;
    margin-top: 0px;
    background-color: #E1E4ED;
  }

  .checklist-container {
    width: 330px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .checkList_completed,
  .checkList_uncompleted {
    width: 260px;
  }

  .addCheckElement {
    min-width: 310px;
    max-width: 310px;
  }
  .checkList_content__rm-btn-container {
    display: flex;
  }
  .checklist-box__control-btns {
    margin: 0;
    gap: 20px;
  }
  .priority-container {
    width: 330px;
    min-height: 100%;
    background-color: transparent;
    box-shadow: none;
    border: none;
    margin-left: auto;
    margin-right: auto;
    align-items: start;
  }
  .checklist-box__info {
    margin: 0px;
  }
  .checklist-box {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .checkList {
    padding-left: 10px;
    margin-bottom: 8px;
  }
  .alertCounterLengthOfTitleModal {
    margin: 0;
    margin-right: 10px;
  }
  .priority-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px 15px;
  }
  .modal-label {
    display: none;
  }
  
  .priority-time {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-top: 24px;
    gap: 0;
  }
  .priority-item {
    margin: 0;

    position: relative;
  }
  .settings-page__avatar-container {
    width: 80px;
    height: 80px;
    margin: 28px 0px 0px 30px;
  }
  .settings-page__avatar-container:hover .avatar {
    background-color: transparent;
  }
  .avatarLogo, .avatar-text {
    display: none;
  }
  .settings-page__avatar-container:hover .settings-page__user-avatar{
    border: none;
    padding: 0;
  }
  .settings-page__user-avatar {
    width: 68px;
    height: 68px;
    object-fit: cover;
  }
  .settings-page__header {
    width: 371px;
    margin: 5px 5px 0px auto;
  }
  .settings-page__header h1 {
    font-size: 14px;
    margin-left: 120px;
  }
  .settings-page__btn-group {
    display: flex;
  }
  .settings-page__btn-close {
    width: 57px;
    height: 24px;
    font-size: 9px;
    padding: 8px auto;
    color: #232360;
    line-height: 9px;
    border-radius: 4px;
  }
  .settings-page__btn-save {
    width: 69px;
    height: 24px;
    font-size: 9px;
    font-weight: 600;
    line-height: 9px;
    padding: 6px 10px;
    margin-left: 10px;
    border-radius: 4px;
  }
  .settings-page__nav-menu {
    margin: 24px 30px 16px 30px;
  }
  .settings-page__nav-list {
    font-size: 12px;
    margin-bottom: 32px;
    justify-content: space-between;
  }
  .settings-page__nav-item {
    margin: 0;
  }
  .settings-page__nav-item:last-child {
    margin: 0;
    margin-right: 0px;
  }
  .stats-main-container {
    margin: 0;
  }
  .a-list-menu {
    margin: 0;
    padding-top: 28px;
  }
  .a-item-menu__mobile {
    display: flex;
    margin-bottom: 28px;
  }
  .a-menu__mobile {
    display: flex;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
    width: 100%;
    text-decoration: none;
  }
  .a-menu__mobile:active,
  .a-menu__mobile:hover {
    color: #FFFFFF;
  }
    .bg_menu-1, 
    .bg_menu-2,
    .bg_menu-3,
    .bg_menu-4,
    .bg_menu-5,
    .bg_menu-6,
    .bg_menu-7,
    .bg_menu-8,
    .bg_menu-9 {
    width: 20px;
    height: 18px;
    display: block;
    background-color: #FFFFFF;
    mask-repeat: no-repeat;
    mask-size: 18px 18px;
    transition: background-color 0.2s ease-in-out;
  }
  .header__menu-mobile {
    display: block;
  }
  .header__menu-desktop {
    display: none;
  }
  .settings-page__container {
    padding: 0;
  }
  .settings-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .settings-profile__item-name {
    flex-direction: column;
  }
  .settings-profile__item-name,
  .settings-profile__item {
    width: 316px;
  }
  .settings-profile__name,
  .settings-profile__other {
    width: 316px;
  }
  
  .date-of-birth-day {
    width: 79px;
  }
  .date-of-birth-month {
    width: 120px;
  }
  .date-of-birth-year {
    width: 95px;
  }
  .settings-profile__datebirth {
    display: none;
  }
  
  .settings-profile__datebirth__mobile {
    display: flex;
  }
  .settings-profile__datebirth__mobile {
    padding: 12px 13px 12px 13px;
  }
  .settings-page__avatar-container:hover .avatar {
    gap: 0px;
  }
  .avatar {
    width: 56px;
    height: 56px;
  }
  .avatarLogo {
    width: 20px;
    height: 20px;
  }
  .avatar-text {
    font-size: 6px;
    line-height: 8px;
  }
  .main-settings-page__container {
    width: 325px;
    height: 30em;
    margin: auto;
  }
  
  .main-settings-page__toogle {
    height: 20px;
  }
  .main-settings-page__title {
    margin-bottom: 0;
    font-size: 14px;
  }
  .main-settings-page__description {
    margin-top: 16px;
    line-height: 20px;
  }
  
  .add-timezone {
    margin-top: 0px;
  }
  .header {
    height: 44px;
    border-bottom: 1px solid #E8E8E8;
    background-color: #F2EEFF;
  }
  .header__logo {
    display: none;
  }
  .header__logo-nologin {
    margin-left: 16px;
    width: 97px;
    height: 20px;
  }
  .user-avatar {
    display: none;
  }
  .btn-menu__mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 8px 6px;
    margin-right: 16px;
    border-radius: 8px;
    border: 0.791px solid #EBEEF2;
    background-color: #FFFFFF;
    box-shadow: 0px 0.791437566280365px 2.37431263923645px 0px rgba(96, 108, 128, 0.05);
  }
  .unrotated-arrow {
    animation-name: rotateArrowBack;
    
    animation-fill-mode: both;
  }
  .rotated-arrow {
    animation-name: rotateArrowForward;
    animation-fill-mode: both;
  }
  .pagePicker {
    min-width: 110px;
    max-width: 159px;
    height: 28px;
    display: flex;
    padding: 6px 8px 6px 6px;
    margin-left: 16px;
    align-items: center; 
    border-radius: 8px;
    font-size: 12px;
    border: 1px solid var(--unnamed, #5932EA);   
  }
  .pagePicker__span {
    display: flex;
    align-items: center;
  }
  .pagePicker__span p {
    color: #5932EA;
    margin-right: 8px;
    margin-left: 4px;
  }
  .add-task__calendar-svg,
  .add-task__time-svg {
    width: 20px;
    height: 20px;
  }
  .add-task__change-group__data {
    width: 190px;
    height: 36px;
    margin: 0;
    margin-right: 12px;
  }
  .add-task__change-group__time {
    width: 176px;
    height: 36px;
    margin-right: 12px;
  }
  .priority__input-time {
    width: 140px;
  }
  .priority__input-date, 
  .priority__input-time {
    font-size: 16px;
    line-height: 16px;
  }
  .priority__input-date {
   width: 154px;
  }
  .priority-span-addTask-0, 
  .priority-span-addTask-1, 
  .priority-span-addTask-2, 
  .priority-span-addTask-3,
  .add-task__add-tags-to-task {
    margin-left: 0;
  }
  .btn-container {
    margin-top: 20px;
  }
  .close-add-task-form {
    width: 66px;
    height: 26px;
    padding: 6px 14px;
    font-size: 10px;
    line-height: 1;
    margin-right: 12px;
  }
  .btn-add-task {
    width: 115px;
    height: 26px;
    padding: 6px 14px;
    font-size: 10px;
    line-height: 1;
    font-weight: 600;
  }
  .tagsList-item {
    margin-top: 12px;
    margin-right: 12px;
  }
  .todo-list__timer {
    display: none;
  }
  .add-task__container__input-field textarea{
    font-size: 16px;
    line-height: 16px;
  }
  .add-task__change-group__priority {
    margin-right: 12px;
  }
  .add-task__change-group__priority,
  .add-task__change-group__tags {
    display: flex;
    width: 130px;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px;
    border-radius: 4px;
    border: 1px solid #E5E7EB;
    background-color: #F9FAFB;
    color: #A4A8B1;
    font-size: 16px;
  }
  .add-task__change-group__tags-description {
    display: block;
    margin-left: 6px;
  }
  .add-task__change-group__priority p {
    display: block;
    margin-left: 6px;
  }
  .add-task__input {
    min-height: 17px;
  }
  .todo-list__tags li:first-child {
    margin-left: 0;
  }
  
}

@media (min-width: 992px) and (max-width: 1439px){
  .submenu__container {
    width: 260px;
  }

  .header {
    height: 64px;
  }
  .submenu__item {
    width: 260px;
  }

  .submenu__item:hover {
    background-size: 5px 36px;
  }

  .submenu__link {
    font-size: 16px;
  }
  .todo-list__container {
    width: 100%;
    padding-top: 36px;
  }
  .todo-list__item__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    width: 100px;
    min-width: 235px;
    padding: 10px 0px;
  }
  .todo-list__item__container__title {
    margin: 0;
    font-size: 14px;
  }
  .todo-list__item__container__description {
    margin: 0;
  }
  .btn-complete-todo {
    border: 2px solid #5932EA;
    border-radius: 4px;
  }
  .settings-page__container {
    padding: 20px 16px 0px 24px;

  }

  .main-settings-page__container {
    margin: 0px 40px 0px 50px;
  }

  .settings-page__avatar-container {
    margin: 124px 0px 0px 50px;
  }

  .settings-page__background {
    height: 200px;
  }

  .settings-page__header {
    margin: 20px 0px 0px 216px;
  }

  .settings-page__nav-menu {
    margin: 44px 0px 16px 50px;
  }

  .submenu__item-icon-1, .submenu__item-icon-2, .submenu__item-icon-3 {
    margin-left: 24px;
    width: 24px;
    height: 24px;
    -webkit-mask-size: 24px 24px;
  }

  .settings-page__header h1 {
    font-size: 24px;
  }

  .settings-page__btn-close, .settings-page__btn-save {
    font-size: 14px;

  }

  .settings-page__btn-close {
    width: 105px;
  }
  
  .settings-page__btn-save {
    margin-left: 10px;
    width: 128px;
  }
  .task-actions-container {
    margin-bottom: 36px;
  }
  .add-task__container {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 36px;
  }
}

@media (max-width: 1600px) and (min-width: 1025px) {
  .settings-page__container {
    padding: 18px 27px 0px 27px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .submenu__item, .submenu__item bg-submenu-2, .submenu__item bg-submenu-3 {
    height: 48px;
  }

  .addTaskBtn {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  
  .addTaskPlus {
    width: 18px;
    height: 18px;
  }

  .filter-container {
    width: 91px;
  }

  .filter-button {
    width: auto;
    height: 30px;
    font-size: 12px;
    padding: 6px;
  }

  .filter-task {
    width: 19px;
    height: 19px;
  }

  .todo-list__item__container__title {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .todo-list__item__container__description {
    font-size: 10px;
    margin-bottom: 0;
  }

  .todo-list__info-container {
    margin-bottom: 0;
  }

  .todo-list__date, .todo-list__datetime, .todo-list__tags {
    font-size: 10px;
  }

  .todo-list__date {
    gap: 2px;
    background-position: 0 0;
  }
  .submenu__item:hover {
    background-size: 4.5px 32px;
  }

  .header {
    height: 64px;
  }
  .login-container {
    height: 72vh;
    justify-content: space-between;
  }
  .register-form__page {
    margin-top: 350px;
    height: auto;
  }
  .submenu__container {
    display: block;
    width: 215px;
  }
  .submenu__link {
    font-size: 14px;
    line-height: 1;
  }
  .submenu__item-active {
    font-size: 14px;
    line-height: 1;
  }
  .submenu__item-icon-1,
  .submenu__item-icon-2,
  .submenu__item-icon-3 {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    margin-right: 12px;
    mask-size: 20px 20px;
  }
  
  .task-actions-container {
    margin-bottom: 24px;
  }
  .todo-list__container {
    width: 100%;
    padding-top: 36px;
  }
  .todo-list__item__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    width: 100px;
    max-width: 540px;
    min-width: 235px;
    padding: 10px 0px;
  }

  .todo-list__item:hover .restore_or_remove_todos, 
  .wrapper-todo:hover .restore_or_remove_todos {
    display: none;
  }

  .btn-complete-todo-container__mobile {
    margin-right: 7px;
    margin-left: 7px;
  }
  .dashboard__time__description,
  .dashboard__status-bar__time-to-tasks,
  .spent_item,
  .task_item,
  .chart_item,
  .end_day_item {
    position: relative;
    left: 0;
    top: 0;
  }
  .wrapper-todo {
    max-width: 718px;
  }
  .add-task__container {
    position: relative;
    width: 100%;
    padding: 15px;
    z-index: 1;
    margin-bottom: 24px;
    margin-top: -65px;
  }
  .add-task__container__input-field {
    padding: 0px;
  }
  .btn-complete-todo {
    border: 2px solid #5932EA;
    border-radius: 4px;
  }
  .add-task__change-group {
    padding: 0px 0px 15px 0px;
  }
  .settings-profile__item-name {
    flex-direction: column;
  }
  .settings-profile__name {
    width: 492px;
  }
  .settings-page__background {
    max-width: 100%;
    min-width: 521px;
    height: 135px;
  }

  .settings-page__avatar-container {
    width: 106px;
    height: 106px;
    margin: 64px 0px 0px 36px;
  }

  .settings-page__user-avatar,
  .avatar {
    width: 94px;
    height: 94px;
  }
  .settings-page__nav-menu {
    margin: 44px 0px 24px 0px;
  }
  .settings-page__nav-list {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
  .settings-page__nav-item {
    margin: 0;
  }
  .settings-profile {
    margin: 74px 0px 0px 48px;
  }
  .modal-content {
    width: 100%;
  }
  .modal-dialog-centered {
    min-height: auto;
  }
  .main-settings-page__container {
    margin-right: auto;
    margin-left: 44px;
  }

  .main-settings-page__list {
    margin-top: 0px;
  }

  .footer-separator {
    display: none;
  }
  .modal-form__container {
    flex-direction: column;
  }
  .modal-form__input-container__focus, 
  .modal-form__input-container,
  .hideBtn-controls-to-modal, 
  .unhideBtn-controls-to-modal {
    width: 455px;
    margin-left: auto;
    margin-right: auto;
  }
  .priority-container {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    border: none;
    margin-top: 10px;
  }

  .priority-list {
    width: 100%;
    padding-left: 30px;
  }
  .checkList_completed, 
  .checkList_uncompleted {
    width: 400px;
  }
  .priority-item {
    position: relative;
  }
  .settings-page__container {
    width: 100%;
    background-color: #F8FAFB;
    padding: 20px 16px 0px 16px;
  }
  .settings-page__header {
    margin: 14px 0px 0px 172px;
  }
  .settings-page__header h1 {
    font-size: 16px;
  }
  .settings-page__btn-close {
    width: 68px;
    height: 30px;
  }
  .settings-page__btn-close, .settings-page__btn-save {
    font-size: 10px;

  }
  .settings-page__btn-save {
    width: 83px;
    height: 30px;
    margin-left: 10px;
  }

  .settings-page__nav-list {
    min-width: 247px;
    max-width: 247px;
    margin: 0;
    margin-left: 43px;
  }
  .settings-page__nav-item {
    font-size: 14px;
    line-height: 1;
  }
  .nav-link,
  .nav-link-active {
    font-size: 14px;
    line-height: 1;
  }
}


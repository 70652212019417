.calendar_block {
    position: relative;
    width: 870px;
    height: 87px;
    background: linear-gradient(91.18deg, #5932EA 0%, #0C0C48 100%), #141522;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

@media (max-width: 1220px) {
    .calendar_block {
        width: 720px;
    }
}

@media (max-width: 1060px) {
    .calendar_block {
        height: 80px;
        width: 100%;
    }
}

@media (max-width: 512px) {
    .calendar_block {
        /* width: 345px; */
        height: 57px;
        margin-bottom: 16px;
    }
}

.week_block {
    max-width: 672px;
    min-width: 672px;
    overflow-x: auto;
    display: flex;


}

@media (max-width: 1220px) {
    .week_block {
        max-width: 518px;
        min-width: 518px;
    }
}

@media (max-width: 1060px) {
    .week_block {
        max-width: 425px;
        min-width: 378px;
    }
}

@media (max-width: 512px) {
    .week_block {
        max-width: 300px;
        min-width: 300px;
        height: 57px;
    }
}

.week_block {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.week_block::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

.calendar_block_btn {
    max-width: 48px;
    min-width: 48px;
    padding-bottom: 8px;
    height: 60px;
    color: white;
    background: rgba(0, 0, 0, 0);
    text-align: center;
}

.calendar_block_btn_date_weekend {
    color: #FFBBB1;
}

@media (max-width: 512px) {
    .calendar_block_btn {
        max-width: 28px;
        min-width: 28px;
        margin-left: 0px;
        margin-right: 0px;
        height: 41px;
    }
}

.calendar_block_hover {
    padding-top: 16px;
    padding-left: 6px;
    height: 87px;
    max-width: 60px;
    min-width: 60px;
    margin-left: 18px;
    margin-right: 18px;
    cursor: pointer;
}

@media (max-width: 1220px) {
    .calendar_block_hover {
        margin-left: 7px;
        margin-right: 7px;
    }
}

@media (max-width: 1060px) {
    .calendar_block_hover {
        padding-left: 2px;
        margin-top: 2px;
        height: 80px;
        max-width: 60px;
        min-width: 60px;
        margin-left: 1px;
        margin-right: 1px;
    }
}

@media (max-width: 512px) {
    .calendar_block_hover {
        padding-left: 0px;
        padding-top: 8px;
        margin-top: 0px;
        height: 57px;
        max-width: 28px;
        min-width: 28px;
        margin-left: 7px;
        margin-right: 7px;
    }
}


.calendar_block_not_hover {
    padding-top: 16px;
    padding-left: 6px;
    height: 87px;
    max-width: 60px;
    min-width: 60px;
    margin-left: 18px;
    margin-right: 18px;
}

@media (max-width: 1220px) {
    .calendar_block_not_hover {
        padding-left: 2px;
        margin-left: 7px;
        margin-right: 7px;
    }
}

@media (max-width: 1060px) {
    .calendar_block_not_hover {
        margin-top: 2px;
        max-width: 52px;
        margin-left: 1px;
        margin-right: 1px;
    }
}

@media (max-width: 512px) {
    .calendar_block_not_hover {
        padding-top: 8px;
        margin-top: 0px;
        height: 57px;
        max-width: 28px;
        min-width: 28px;
        margin-left: 7px;
        margin-right: 7px;
    }
}



.calendar_block_hover:hover {
    background: rgba(172, 152, 246, 0.25);
    box-shadow: 0px 0.8499999046325684px 2.549999713897705px 0px rgba(96, 108, 128, 0.05);
}

.calendar_block_btn_active {
    border-bottom: solid 2px #E1E4ED;
}

.calendar_block_btn_not_active {
    color: rgba(225, 228, 237, 0.30);
}

.calendar_block_btn_date {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
}

@media (max-width: 1220px) {
    .calendar_block_btn_date {
        font-size: 20px;
    }
}

@media (max-width: 1060px) {
    .calendar_block_btn_date {
        font-size: 14px;
    }
}

@media (max-width: 512px) {
    .calendar_block_btn_date {
        font-size: 12px;
    }
}


.calendar_block_btn_day {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

@media (max-width: 1060px) {
    .calendar_block_btn_day {
        font-size: 10px;
    }
}

@media (max-width: 512px) {
    .calendar_block_btn_day {
        font-size: 9px;
    }
}

.calendar_block_left_notactive {
    margin-left: 32px;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar_block_left {
    margin-left: 32px;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1060px) {
    .calendar_block_left_notactive {
        margin-left: 0px;
    }

    .calendar_block_left {
        margin-left: 0px;
    }
}

@media (max-width: 512px) {
    .calendar_block_left_notactive {
        display: none;
    }

    .calendar_block_left {
        display: none;
    }
}


.calendar_block_left:hover {
    border: 0.95px solid var(--gray-50, #EBEEF2);
    border-radius: 7.6px;
    background: #FFF;
}

.left_img_notactive {
    fill: rgba(225, 228, 237, 0.30);
}

@media (max-width: 1060px) {
    .left_img_notactive {
        width: 18px;
        height: 18px;
    }
}

.left_img {
    fill: #E1E4ED;
}

@media (max-width: 1060px) {
    .left_img {
        width: 18px;
        height: 18px;
    }
}

.calendar_block_left:hover .left_img {
    fill: #5932EA;
}

@media (max-width: 450px) {
    .calendar_block_left {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.calendar_block_right {
    width: 38px;
    height: 38px;
    margin-right: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1060px) {
    .calendar_block_right {
        margin-right: 0px;
    }
}


@media (max-width: 512px) {
    .calendar_block_right {
        display: none;
    }
}

.calendar_block_right:hover {
    border: 0.95px solid var(--gray-50, #EBEEF2);
    border-radius: 7.6px;
    background: #FFF;
}

.right_img {
    fill: #E1E4ED;
}

@media (max-width: 1060px) {
    .right_img {
        width: 18px;
        height: 18px;
    }
}

.calendar_block_right:hover .right_img {
    fill: #5932EA;
}

.calendar_block_calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43.2px;
    height: 43.2px;
    border-radius: 7.6px;
    cursor: pointer;
}

.calendar_block_calendar:hover {
    border: 0.95px solid var(--gray-50, #EBEEF2);
    background: #FFF;
}

@media (max-width: 512px) {
    .calendar_block_calendar {
        width: 28px;
        height: 28px;
        padding: 5px;
        border-radius: 4px;
    }
}

.calendar_block_calendar_img {
    width: 28px;
    height: 28px;
    fill: #FFF;
}

.calendar_block_calendar:hover .calendar_block_calendar_img {
    fill: #5932EA;
}

@media (max-width: 450px) {
    .calendar_block_calendar {
        width: 33px;
        height: 33px;
    }
}

.feedback__description {
    width: 444px; 
    height: 120px; 
    padding: 8px 12px; 
    border: 1px solid #E1E4ED;
    transition: border 0.1s ease-in-out; 
    border-radius: 8px; 
    outline: none; 
    resize: none;
    overflow: hidden;
    color: #232360;
}

.feedback__uploadFiles {
    max-width: 200px;
    width: fit-content;
    display: flex;
    flex-grow: 0;
    flex-basis: 0;
    max-height: 40px;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 1px solid #E1E4ED;
    border-radius: 8px; 
    padding: 10px 16px;
    box-shadow: 0px 1px 3px 0px #606C800D;
    transition: border 0.2s ease-in-out;
    font-size: 14px;
    color: #232360;
}

.uploadFiles__disabled {
    pointer-events: none;
    color: #A4A8B1;
}

.feedback__uploadFiles:hover{
    border: 1px solid #5932EA;
    transition: border 0.2s ease-in-out;
}

.feedback__description::-webkit-input-placeholder {
    text-indent: 0px;
    opacity: 1;
    transition: text opacity 0.4s ease-in-out;
}
.feedback__description::-moz-placeholder {
    text-indent: 0px;
    opacity: 1;
    transition: text opacity 0.4s ease-in-out;
}
.feedback__description:-moz-placeholder {
    text-indent: 0px;
    opacity: 1;
    transition: text opacity 0.4s ease-in-out;
}
.feedback__description:-ms-input-placeholder {
    text-indent: 0px;
    opacity: 1;
    transition: text opacity 0.4s ease-in-out;
}

.feedback__description:focus::-webkit-input-placeholder {
    white-space: nowrap;
    text-indent: -200px;
    opacity: 0;
    transition: text opacity 0.4s ease-in-out;
  }
  
.feedback__description:focus::-moz-placeholder {
    white-space: nowrap;
    text-indent: -200px;
    opacity: 0;
    transition: text opacity 0.4s ease-in-out;
  }
  
.feedback__description:focus:-moz-placeholder {
    white-space: nowrap;
    text-indent: -200px;
    opacity: 0;
    transition: text opacity 0.4s ease-in-out;
  }
  
.feedback__description:focus:-ms-input-placeholder {
    white-space: nowrap;
    text-indent: -200px;
    opacity: 0;
    transition: text opacity 0.4s ease-in-out;
  }

.feedback__description:focus {
    border: 1px solid #5932EA;
    transition: border 0.1s ease-in-out;
}

.feedback__send{
    margin-right: auto;
    margin-left: auto;
    padding: 10px 16px;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    border: none;
    border-radius: 8px;
    outline: none;
    background-color: #5932EA;
}

.feedback__loader {
    display: flex; 
    padding: 100px 150px;
}

.feedback__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 54px;
}

.feedback__success h1 {
    font-size: 24px;
    line-height: 36px;
    color: #232360;
    font-weight: 600;

}

.feedback__recomendation {
    line-height: 20px;
}

.feedback__btn {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #A4A8B1;
    background-color: transparent;
    border: 1px solid #E1E4ED;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    border-radius: 8px;
    transition: all 0.1s ease-in-out; 
}

.feedback__btn:hover {
    border: 1px solid #5932EA;
    color: #5932EA;
    transition: all 0.1s ease-in-out; 
}

.icon-btn {
    fill: #A4A8B1;
    transition: all 0.1s ease-in-out; 
}

.feedback__btn:hover .icon-btn {
    fill: #5932EA;
    transition: all 0.1s ease-in-out; 
}

.list {
    display: flex; 
    align-items: center; 
    gap: 8px; 
    margin-left: 8px;
}

.list__dot {
    display: block; 
    width: 4px; 
    height: 4px; 
    border-radius: 50%; 
    background-color: #232323;
}

.feedback__recomendation ul :first-child {
    align-items: baseline;
}

@media (max-width: 576px) {
    .feedback__description,
    .feedback__recomendation  {
        max-width: 319px
    }
    .feedback__success {
        padding: 0px 19.5px;
    }
    .feedback__success h1 {
        font-size: 20px;
    }
    .feedback__loader{
        padding: 50px 100px;
    }
    .feedback__btn {
        background-color: #fff;
        padding: 0px 7.2px;
        gap: 0px;
    }
    .feedback__btn p {
        display: none;
    }
}
.editTaskloader {
    margin-left: auto;
}

.transferTaskCount {
    display: flex;
    gap: 6px;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 10px;
    margin-right: 24px;
}

.transferTaskCount p {
    pointer-events: none;
}


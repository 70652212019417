.menu_filter_container{
    position: absolute; 
    top: 8px;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 190px;
    height: 272px;
    padding: 15px 0;
    background-color: #FFFFFF;
    box-shadow: 0 0 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px;
}

.sort_title{
    display: flex;
    flex-direction: row;
    line-height: 24px;
    width: auto;
    cursor: default;
    padding: 0 15px;
}

.sort_title_title{
    margin-right: 17.5px;
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: #232360;
}

.select_order{
    width: 18px;
    height: 11px;
    margin: auto 0;    
}

.no_select_order{
    background-image: url(../../icons/svg/noSortMethod.svg);
}

.true_select_order{
    background-image: url(../../icons/svg/falseSortMethod.svg);
    cursor: pointer;
}

.false_select_order{
    background-image: url(../../icons/svg/trueSortMethod.svg);
    cursor: pointer;
}

.sort_method{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    line-height: 24px; 
    padding: 0 15px;
    cursor: pointer;
}

.sort_method:hover{
    background-color: #F2EEFF;
}

.sort_method_title{
    color: #232360;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
}

.selected_method{
    width: 11px;
    height: 8px;
    margin: auto 0;
    background-image: url(../../icons/svg/selected_method.svg);
}
.filter_table_head {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 400px) {
    .filter_table_head {
        gap: 8px;
    }
}

.sort_icon {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .sort_icon {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 400px) {
    .sort_icon {
        width: 16px;
        height: 16px;
    }
}
.page-info__completed-icon {
    width: 36px;
    height: 36px;
    margin-right: 8px;
}

@media (min-width: 992px) and (max-width: 1024px) {
    .page-info__completed-icon {
        width: 32px;
        height: 32px;
    }
}
  
@media (max-width: 576px) {
    .page-info__completed-icon {
        width: 20px;
        height: 20px;
    }
}
  
@media (min-width: 768px) and (max-width: 991px) {
    .page-info__completed-icon {
        width: 28px;
        height: 28px;
    }
}

.AuthVariants__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
}

.AuthVarinats__descr {
    line-height: 19px;
    font-size: 14px;
    color: #A4A8B1;
}

.AuthVariants__btn-group {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.YaAuth,
.vkAuth {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    box-sizing: border-box;
}

.YaAuth:focus,
.vkAuth:focus {
    outline: 1px solid blue;
}

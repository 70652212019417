.selectPage-container {
    position: absolute;
    top: 44px;
    left: 16px;
    display: flex;
    width: 168px;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 25px 0px rgba(35, 35, 96, 0.15);
    z-index: 9999;
}

.selectPage-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
}

.selectPage-item {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    color: #232360;
    margin-bottom: 20px;
}

.selectPage-item:last-child {
    margin-bottom: 0;
}

.selectPage-svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.selectPage-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #232360;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}
.FailureAuth {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 92vh; 
}

.FailureAuth__description {
    width: 100%;
    max-width: 610px;
    text-align: center;
    font-size: 20px;
    line-height: 27px;
    color: #232360;
    margin-bottom: 32px;
}

.FailureAuth__description__colored {
    color: #5932EA;
}

.FailureAuth__btn-group {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 576px)  {
    .FailureAuth {
        margin: 0px 28px;
    }

    .FailureAuth__description {
        font-size: 16px;
    }
}
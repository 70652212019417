.priority__container,
.priority__container__edit-task {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 204px;
    height: 234px;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px; 
}

.priority__container {
    top: 15px;
    left: 50%;
    right: 50%;
    transform: translate(134%, 0%);
}

.priority__container__edit-task {
    top: -32px;
    right: 10px;
}

.priority__list {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.priority__list li {
    cursor: pointer;
}

.priority__item {
    display: flex;
    align-items: center;
    width: 160px;
    height: 34px;
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
    transition: border 0.2s ease-in-out;
}

.priority__item:last-child {
    margin-bottom: 0;
}

.priority-0,
.priority-1,
.priority-2,
.priority-3 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 10px;
}

.priority__item:hover {
    border: 1px solid #5932EA;
    transition: border 0.2s ease-in-out;

}

.priority-0 {
    background-color: #E1E4ED;
}

.priority-1 {
    background-color: #F85640;
}

.priority-2 {
    background-color: #F8C440;
}

.priority-3 {
    background-color: #32EAA8;
}


.priority__box {
    display: flex;
    
}

.active-priority {
    background-image: url(../../icons/svg/check-priority.svg);
    background-repeat: no-repeat;
    background-position: 135px center;
}

.priority__decription {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    color: #232360;
}

@media (max-width: 576px) {
    .priority__container {
        right: 0%;
        left: 0%;
        transform: translate(0%, 0%);
    }
    .priority__container,
    .priority__container__edit-task {
        top: 10px;
        left: 0px;
      }
      
}

@media (min-width: 768px) and (max-width: 991px) {
    .priority__container,
    .priority__container__edit-task {
        top: 320px;
        left: 190px;
      }
}
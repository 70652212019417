.privacy {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.privacy h1 {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
}

.privacy a {
    color: #5932EA;
    text-decoration: none;
}

.privacy table {
    border: 1px solid #000;
}

.privacy tr {
    border: 1px solid #000;
}

.privacy td {
    padding: 10px;
    border: 1px solid #000;
}

.privacy__ol-header {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 576px) {
    .privacy {
        padding-left: 20px;
        padding-right: 20px;
    }
    .privacy h1 {
        font-size: 18px;
    }
    .privacy__ol-header {
        font-size: 16px;
    }
}
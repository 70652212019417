.TopTags-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(313.12deg, #5932EA 0.01%, #40C0F8 100.01%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
    border-radius: 16px;
}

.TopTags-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    
}

.TopTags-content__heading {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 27px;
    margin: 0;
    margin-right: 12px;
    cursor: default;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.tags-span {
    margin-top: 16px;
}

.TopTags-content__select {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 24px;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    z-index: 9;
    
}

.TopTags-content__list {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.TopTags-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 352px;
    height: 128px;
    margin-right: 38px;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.TopTags-list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    cursor: default;
}

.TopTags-list__item:last-child {
    margin-bottom: 0;
}


.input-select__data-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    top: 52px;
    width: 132px;
    height: 76px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #232360;
    cursor: default;
    background-color: #fff;
    border-radius: 10px;
    z-index: 99;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

.input-select__data-item {
    width: 100%;
    cursor: pointer;
}

.selected-data-item {
    color: #FFFFFF;
}

.active-select {
    background-image: url(../../../../../../icons/svg/check-priority.svg);
    background-repeat: no-repeat;
    background-position: 85px center;
}

.TopTags-list__description {
    width: 100%;
    text-align: start;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
}

.TopTags-list__data {
    color: #E1E4ED;
}

.TopTags-list__data,
.TopTags-list__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.TopTags-tasks-stats__select {
    position:static;
    display: flex;
    align-items: center;
    width: 135px;
    height: 32px;
    font-size: 16px;
    padding-left: 8px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #EBEEF2;
    background-color: rgba(255, 255, 255, 0.1);;
    background-size: 14px 8px;
    background-position: 110px center;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.TopTags-list__bg {
    display: block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@media (max-width: 576px) {
    .TopTags-container {
        width: 100%;
        height: 100%;
    }
    .TopTags-list__data {
        display: none;
    }
    .TopTags-list {
        width: 128px;
        height: 89px;
        margin-right: 0;
        margin-bottom: 0;
        justify-content: space-between;
    }
    .TopTags-content__list {
        justify-content: space-between;
        margin-left: 24px;
        margin-top: 16px;
        width: 232px;
        height: 90px;
    }
    .TopTags-list__item {
        justify-content: flex-start;
        margin-bottom: 8px;
    }
    .TopTags-list__description {
        max-width: 115px;
        font-size: 9.6px;
        line-height: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .TopTags-list__bg {
        width: 8px;
        height: 8px;
    }
    .TopTags-content__heading {
        font-size: 12px;
        line-height: 1;
    }
    .TopTags-tasks-stats__select {
        width: 103px;
        height: 25px;
        background-position: 82px center;
        padding-left: 6px;
    }
    .selected-data-item {
        font-size: 12px;
        line-height: 1;
    }
    .input-select__data-list {
        left: 110px;
    }
}



@media (max-width: 1600px) and (min-width: 1280px) {
    .TopTags-content__list {
        margin: 0;
    }
    .TopTags-list {
        max-width: 238px;
        margin-right: 24px;
    }
    .TopTags-list__data,
    .TopTags-list__description {
        font-size: 12px;
    }
    .TopTags-content__heading {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 856px) {
    .TopTags-container {
        height: 173px;
    }
    .TopTags-content__select {
        margin-top: 12px;
        margin-left: 20px;
        gap: 8px;
    }
    .TopTags-content__heading {
        font-size: 12px;
        margin: 0;
    }
    .TopTags-list {
        height: auto;
        max-width: 127px;
        margin-right: 20px;
        gap: 9px;
    }
    .TopTags-list__description {
        font-size: 10px;
        line-height: 1;
    }
    .TopTags-list__item {
        height: 16px;
        margin: 0;
    }
    
}
@media (min-width: 857px) and (max-width: 1024px) {
    .TopTags-list {
        height: auto;
        max-width: 176px;
        margin-right: 24px;
        gap: 16px;
    }
    .TopTags-list__item {
        height: 20px;
        margin: 0;
    }
    .TopTags-container {
        height: 220px;
    }
    .TopTags-content__select {
        gap: 12px;
    }
    .TopTags-content__heading {
        font-size: 16px;
        margin: 0;
    }
    .TopTags-list__data {
        display: none;
    }
} 


@media (min-width: 768px) and (max-width: 1023px) {
    .TopTags-container {
        max-width: 362px;
        min-width: 246px;
        height: 173px;
        border: 0.8px solid #E1E4ED;
        box-shadow: 0px 0.8px 2.4px 0px rgba(96, 108, 128, 0.05);
        border-radius: 12.8px;
    }
    .TopTags-list__data {
        display: none;
    } 
    .TopTags-content__list {
        
    }
}
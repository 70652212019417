.priority_box {
    display: flex;
    gap: 10px;
}

.priority-0,
.priority-1,
.priority-2,
.priority-3 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 10px;
}

.priority-0 {
    background-color: #E1E4ED;
}

.priority-1 {
    background-color: #F85640;
}

.priority-2 {
    background-color: #F8C440;
}

.priority-3 {
    background-color: #32EAA8;
}

.priority_decription {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #232360;
}

@media (max-width: 768px) {
    .priority_decription {
      font-size: 10px !important;
      white-space: nowrap;
    }
  }
  
  @media (max-width: 400px) {
    .priority_decription {
      font-size: 8px !important;
      white-space: nowrap;
    }
  }

  @media (max-width: 1600px) and (min-width: 1280px) {
    .priority_decription{
        font-size: 12px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 856px) {
    .priority_decription{
      font-size: 10px;
    }
    
  }
  
  @media (min-width: 857px) and (max-width: 1024px) {
    .priority_decription{
        font-size: 12px;
    }
  }
.failureGoogle {

}

.failureGoogle__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.failureGoogle__content p {
    text-align: center;
    font-size: 20px;
    line-height: 36px;
}

.successGoogle,
.failureGoogle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 92vh;
}

.successGoogle h1,
.failureGoogle h1 {
    text-align: center;
    max-width: 392px;
    color: #232360;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;   
}

.successGoogle__content {
    display: flex;
    gap: 16px;
    flex-direction: row;
    align-items: flex-start;
}

.failureGoogle__content__row1 {
    display: flex;
    gap: 16px;
    margin-bottom: 4px;
}

.google-highlight {
    color: #5932EA;
}

@media (max-width: 576px) {
    .successGoogle__content {
        flex-direction: column;
        align-items: center;
    }
    .successGoogle h1,
    .failureGoogle h1 {
        font-size: 20px;
    }
    .failureGoogle__content__row1 {
        flex-direction: column;
        align-items: center;
    }
    .failureGoogle__content p {
        max-width: 190px;
        font-size: 16px;
        line-height: 24px;
    }
  }
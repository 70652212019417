.stats-period-select {
    position:static;
    display: flex;
    align-items: center;
    max-width: 135px;
    height: 40px;
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    color: #232360;
    border: 1px solid #EBEEF2;
    border-radius: 8px;
    background-color: rgba(255, 255, 255);;
    background-size: 14px 8px;
    background-position: 100px center;
    background-image: none;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0px 1.2530221939086914px 3.759066104888916px 0px #606C800D;
}
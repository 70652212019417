.deleted-page {
    display: flex;
    justify-content: space-between;
}

.page-info__deleted-icon {
    width: 36px;
    height: 36px;
    margin-right: 8px;
}

.empty-the-trash{
    all: unset;
    font-weight: 600;
    padding: 8px;
    background-color: #fff;
    color: #F85640;
    border: 1px solid #F85640;
    border-radius: 8px;
    line-height: 1.3;
    box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  }

@media (min-width: 992px) and (max-width: 1024px) {
    .page-info__deleted-icon {
        width: 32px;
        height: 32px;
    }
}
  
@media (max-width: 576px) {
    .page-info__deleted-icon {
        width: 20px;
        height: 20px;
    }
    .empty-the-trash {
        font-size: 12px;
        line-height: 1.2;
    }
}
  
@media (min-width: 768px) and (max-width: 991px) {
    .page-info__deleted-icon {
        width: 28px;
        height: 28px;
    }
}

.BadForecast-container,
.BestForecast-container {
    width: 100%;
    height: 100%;
    border: 1px solid #E1E4ED;
    box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
    border-radius: 12px;
}

.BadForecast-container {
    background: linear-gradient(313.12deg, #F95050 0.01%, #F9AB50 100.01%);
}

.BestForecast-container {
    background: linear-gradient(313.12deg, #32EAA8 0.01%, #1EA7FF 100.01%, #5051F9 100.01%);
}

.BadForecast-content,
.BestForecast-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: default;
}

.BadForecast-header,
.BestForecast-header {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #FFFFFF;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.Forecast-description {
    max-width: 266px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.80);
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    text-align: center;
}

.Forecast-description a {
    color: #FFFFFF;
    opacity: 80%;
    text-decoration: none;
}

.Forecast-description:hover a {
    opacity: 100%;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }

 
 
 @media (max-width: 576px) {
    .BadForecast-container,
    .BestForecast-container {
        width: 100%;
        height: 176px;
    }
    .BadForecast-header,
    .BestForecast-header {
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
    }
    .Forecast-description {
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
        line-height: 1;
    }
    .mobile-br-hidden {
        display: none;
    }
}

@media (min-width: 400px) and (max-width: 576px){
    .BadForecast-container,
    .BestForecast-container {
        height: 193px;
    }
 }

@media (max-width: 1600px) and (min-width: 1280px) {
    .BadForecast-header,
    .BestForecast-header {
        font-size: 16px;
    }
    .Forecast-description {
        font-size: 12px;
        max-width: 200px;
    }
}

@media (min-width: 768px) and (max-width: 856px) {
    .BadForecast-header,
    .BestForecast-header {
        font-size: 12px;
    }
    .BadForecast-container,
    .BestForecast-container {
        height: 176px;
    }
    .Forecast-description {
        font-size: 12px;
        max-width: 200px;
    }
}

@media (min-width: 857px) and (max-width: 1024px) {
    .BadForecast-container,
    .BestForecast-container {
        height: 220px;
    }
    .BadForecast-header,
    .BestForecast-header {
        font-size: 16px;
    }
    .Forecast-description {
        font-size: 12px;
    }
} 

@media (min-width: 768px) and (max-width: 1023px) {
    .BadForecast-container,
    .BestForecast-container {
        width: 100%;
        min-width: 220px;
        max-width: 320px;
        border: 0.8px solid #E1E4ED;
        box-shadow: 0px 0.8px 2.4px 0px rgba(96, 108, 128, 0.05);
        border-radius: 9.6px;
}
}
.tags__container,
.tags__container__edit-task {
    display: flex;
    flex-direction: column;
    min-width: 271px;
    max-width: 750px;
    height: 255px;
    position: absolute;
    padding: 0;
    background-color: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px; 
}

.tags__container {
    top: 15px;
    left: 50%;
    right: 0;
    transform: translate(115%, 0%);
}

.tags__container__edit-task {
    top: -32px;
    right: 10px;
}

.tags__data-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}

.tags__data-nothing {

}

.tags__get-tag-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tags_get-tag__input {
    min-width: 200px;
    max-width: 750px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 24px 16px 20px;
}

.ddselect-alert {
    margin: 0;
    margin-left: 20px;
}

.tags__data-container::-webkit-scrollbar-track {
    background-color: transparent;
    position: absolute;
}

.tags__data-container::-webkit-scrollbar {
    width: 5px;
}

.tags__data-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #A4A8B1;
}

.tags__get-tag {
    min-width: 200px;
    max-width: 477px;
    height: 24px;
    outline: none;
    padding: 0;
    border: 1px solid transparent;
    
    background-color: transparent;
    color: #A4A8B1;
}

.tags__line {
    width: 100%;
    height: 1px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #A4A8B1;
    margin-bottom: 2px;
    border-bottom: 1px solid #E5E7EB;
}

.tags__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 24px;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.tags__item {
    min-width: 271px;
    max-width: 750px;
    
}

.tags__item:hover {
    cursor: pointer;
    background-color: #F2EEFF;
}

.notmatch-container {
    min-width: 271px;
    max-width: 750px;
    height: 26px;
    display: block;
}

.tags-box {
    min-width: 271px;
    max-width: 750px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: 400;
    background-image: url(../../icons/svg/TagsDDSelect/list-style.svg);
    background-repeat: no-repeat;
    background-position: 21px center;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 13px;
    transition: background-color 0.3s ease-in-out;
}
.tags__title {
    min-width: auto;
    max-width: 750px;
    height: auto;
    font-size: 12px;
    padding-right: 4px;
    line-height: 16px;
    color: #A4A8B1;
}

.tags-box input {
    display: none;
}
  
.tags-box span {
    height: 13.5px;
    width: 13.5px;
    margin-right: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color:transparent;
    border-radius: 4px;
    transition: border 0.3s ease-in-out;
}

.tags-box p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-box span:hover {
    border: 1px solid  #5932EA;
    transition: border 0.3s ease-in-out;
}
  
.tags-box [type=checkbox]:checked + span:before {
    width: 13.5px;
    height: 13.5px;
    content: '0';
    top: -0.8px;
    right: -1.2px;
    position: absolute;
    color: transparent;
    background-image: url(../../icons/svg/Subtract-colored.svg);
    background-repeat: no-repeat;
}
  
.tags-box [type=checkbox]:checked + span:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.addPlus {
    fill: #A4A8B1;
    transition: fill 0.2s ease-out;
}

.addTagFromFinder {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 102px;
    height: 26px;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: #A4A8B1;
    border-radius: 6px;
    background-color: transparent;
    margin-top: 15px;
    margin-left: 20px;
    transition: all 0.2s ease-out;
}

.addTagFromFinder:hover {
    color: #FFFFFF;
    background-color: #5932EA;
    transition: all 0.2s ease-out;
}

.addTagFromFinder:hover .addPlus {
    fill: #FFFFFF;
    transition: fill 0.2s ease-out;
}



@media (max-width: 576px) {
    .tags__container {
        left: 0%;
        right: 0%;
        transform: translate(0%, 0%);
    }
    .tags__container,
    .tags__container__edit-task {
        top: 10px;
        right: 0px;
        width: 308px;
      }
    .tags__list {
        gap: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tags__container {
        top: 400px;
        right: 353px;
      }
}
.paginationBtn {
    padding: 8px 20px;
    border: 1px solid #E1E4ED;
    border-radius: 8px;
    outline: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #232323;
    margin-bottom: 20px;
    transition: border 0.1s ease-in-out;
}

.paginationBtn:hover {
    border: 1px solid #5932EA;
    transition: border 0.1s ease-in-out;
}
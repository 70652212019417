.tags-page-list {
    display: flex;
    justify-content: center;
}

.tags-page__container {
    max-width: 429px;
    padding-top: 36px; 
}

.tags-container {
    display: flex;
    flex-direction: column;
     margin: 31px auto auto 80px;
}

.tags-list__item{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.tags-list__item__container{
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 429px;
    padding: 8px;
    border: 1px solid #E1E4ED;
    border-radius: 6px;
    background-color: #FFFFFF;
    transition: 0.5s;
}

.tags-list__item__container:hover{
    border-color: #5932EA;
}


.tags-list__item__container:hover .btn-remove-tags{
    display: block;
    z-index: 10;
}

.tags-hash__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.tags-hash{
    position: absolute;
    width: 16px;
    height: 16px;
}

.tags-title{
    max-width: 370px;
    font-size: 14px;
    line-height: 1.25;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.form-edit-tags{
    display: flex;
    transition: 1s;
}

.input-edit-tags{
    display: block;
    border: none;
    outline: none;
    padding: 0;
    font-size: 14px;
    line-height: 1.25;
  }

.input-add-tags{
    all: unset;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 35px;
    width: 409px;
    font-size: 14px;
    
    
    
    background-color: transparent;
}

.btn-remove-tags,
.btn-remove-tags__mobile {
    display: none;
    width: 15px;
    height: 16px;
    border: none;
    cursor: pointer;
    background-color: #A4A8B1;  
    background-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    transition: 0.5s;
    margin: auto;
    margin-right: 8px;
    mask-image: url(../../src/icons/svg/remove-tags.svg);
  }
  
.add-tag-container {
    width: 429px;
    min-height: 35px;
    border: 1px solid #5932EA;
    border-radius: 6px;
    padding: 0 10px; 
    background-color: #fff;
}
.btn-remove-tags:hover{
    background-color:#5932EA;
  }

.btn-add-tags-group {
    margin-top: 20px;
}

@media (max-width: 576px) {
    .tags-page__container {
        width: 335px;
        padding-top: 20px;
    }
    .tags-hash__logo {
        width: 16px;
        height: 16px;
    }
    .tags-container {
        margin: 16px auto auto 14.5px;
    }
    .tags-list__item__container:hover .btn-remove-tags{
        display: none;
        z-index: 10;
    }
    .tags-list__item__container:hover .btn-remove-tags__mobile{
        display: block;
        z-index: 10;
        background-color: #5932EA;
    }
    .input-add-tags {
        width: 315px;
    }
    .add-tag-container {
        width: 335px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tags-page__container {
        width: 100%;
    }
    .tags-hash__logo {
        width: 28px;
        height: 28px;
    }
    .tags-page__container {
        padding-top: 24px;
    }
    .tags-list__item{
        margin-bottom: 20px;
    }
    .tags-container {
         margin: 32px auto auto 56px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .tags-hash__logo {
        width: 32px;
        height: 32px;
    }
    .tags-page__container {
        padding-top: 24px;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {

}
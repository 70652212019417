.task-menu__container {
    padding: 15px;
    position: absolute;
    top: 10px;
    transform: translateX(-50px);
    background-color: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 0px 25px rgba(35, 35, 96, 0.15);
    border-radius: 10px; 
}

.task-menu__list {
    display: flex;
    min-width: 50px;
    max-width: 130px;
    width: 100%;
    flex-direction: column;
    margin: 0;
    gap: 12px;
}

.task-menu__list li {
    cursor: pointer;
}

.task-menu__item:hover .task-menu__description {
    color: #5932EA;
    transition: color 0.2s ease-in-out;
}

.task-menu__item:hover .bg-task-menu-hov {
    background-color: #5932EA;
    transition: background-color 0.2s ease-in-out;
}

.bg-task-menu-0,
.bg-task-menu-1,
.bg-task-menu-2,
.bg-task-menu-3 {
    width: 18px;
    height: 18px;
    mask-repeat: no-repeat;
    mask-position: center center;
    background-color: #232360;
    transition: background-color 0.2s ease-in-out;
}

.bg-task-menu-0 {
    mask-image: url(../../icons/svg/menu_ic/trash.svg);
}

.bg-task-menu-1 {
    mask-image: url(../../icons/svg/menu_ic/circle.svg);
}

.bg-task-menu-2 {
    mask-image: url(../../icons/svg/menu_ic/CopySimple.svg);
}

.bg-task-menu-3 {
    mask-image: url(../../icons/svg/menu_ic/uncomplete.svg);
    mask-size: 18px;
}

.task-menu__item {
    display: flex;
    align-items: center;
    height: 16px;
    white-space: nowrap;
}

.task-menu__description {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    line-height: 16px;
    padding-left: 10px;
    color: #232360;
    transition: color 0.2s ease-in-out;
}

@media (max-width: 576px)  {
    .task-menu__list {
        gap: 20px;
    }
    .task-menu__container {
        top: 10px;
        right: 24px;
        transform: translateX(0);
    }
}
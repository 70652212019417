.priority__input-modalwindow {
    width: 211px;
    height: 34px;
    font-size: 12px;
    line-height: 16px;
    color: #232360;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    background-color: #F9FAFB;
    transition: border 0.2s ease-in-out;
}

.priority__input-modalwindow:hover  {
    border: 1px solid #5932EA;
    transition: border 0.2s ease-in-out;
}

.priority__input-modalwindow:disabled:hover {
    border: 1px solid #E5E7EB;
}

.priority__input-date-modal {
    text-align: left;
    padding-left: 36px;
    background-image: url(../icons/svg/ModalChangingTask/calendar_colored.svg);
    background-repeat: no-repeat;
    background-position: 12px center;
}

.priority__input-date-modal:disabled {
  color: #A4A8B1;
  background-image: url(../icons/svg/ModalChangingTask/calendar_disabled.svg);
}

.priority-input-0,
.priority-input-1,
.priority-input-2,
.priority-input-3 {
  padding-left: 36px;
  text-align: left;
  border: 1px solid #E1E4ED;
  outline: none;
  background-color: #F9FAFB;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: background 0.2s ease-in-out;
}

.priority-input-0:disabled,
.priority-input-1:disabled,
.priority-input-2:disabled,
.priority-input-3:disabled {
  color: #A4A8B1;
}

.priority-input-0 {
  background-image: url(../icons/svg/ModalChangingTask/Ellipse-pr-0.svg);
    transition: background 0.2s ease-in-out;

}
.priority-input-1 {
  background-image: url(../icons/svg/ModalChangingTask/Ellipse-pr-1.svg);
    transition: background 0.2s ease-in-out;

}
.priority-input-2 {
  background-image: url(../icons/svg/ModalChangingTask/Ellipse-pr-2.svg);
    transition: background 0.2s ease-in-out;

}
.priority-input-3 {
  background-image: url(../icons/svg/ModalChangingTask/Ellipse-pr-3.svg);
    transition: background 0.2s ease-in-out;

}
.priority__input-tags-modal {
    padding-left: 36px;
    background-image: url(../icons/svg/CalendarDDSelect/Union-item.svg);
    background-repeat: no-repeat;
    background-position: 12px center;
}

.priority__input-tags-modal  {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    color: #A4A8B1;
}

.priority__tags-item {
    display: flex;
    height: 16px;
    margin-bottom: 8px;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
    background-image: url(../icons/svg/ModalChangingTask/grid-tag-add-tag.svg);
    background-repeat: no-repeat;
    background-position: left center;
    transition: all 0.2s ease-in-out;
}

.priority__tags-item:last-child {
  margin-bottom: 0;
}

.test-block {
    overflow: hidden;
    margin-left: 10px;
    margin-top: 12px;
    width: 200px;
    /* min-height: 300px; */
}

.priority__tags-item:hover .priority__remove-tag {
    display: block;
}

.priority__remove-tag{
    display: none;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background-image: url(../icons/svg/CalendarDDSelect/cancel-btn.svg);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center;
}

.priority__remove-tag:hover {
  display: block;
  width: 12px;
  height: 12px;
  background-image: url(../icons/svg/CalendarDDSelect/remove-tag_hover.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}

.priority__tags-title {
  max-width: 135px;
  font-size: 12px;
  line-height: 16px;
  color: #A4A8B1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.centered-btn-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 12px;
}
.btn-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8px;
  height: 18px;
  margin-top: -2px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.btn-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.btn-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.btn-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes btn-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px)  {
  .test-block {
    display: none;
  }
  .priority__input-date-modal {
    width: 120px;
    font-size: 10px;
    text-align: center;
    padding-left: 28px;
    background-size: 12px;
    background-position: 8px center;
  }
  .priority-input-0,
  .priority-input-1,
  .priority-input-2,
  .priority-input-3 {
    min-width: 75px;
    width: 100%;
    max-width: 114px;
    padding-left: 24px;
    padding-right: 8px;
    font-size: 10px;
    background-position: 8px center;
    background-size: 12px;
    transition: none;
  }
  .priority__input-tags-modal{
    width: 63px;
    font-size: 10px;
    background-image: url(../../src/icons/svg/grid-add-task.svg);
    background-size: 12px;
    background-position: 8px center;
    padding-left: 24px;
    padding-right: 8px;
  }

}
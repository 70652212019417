.tooltip_conatiner {
  position: relative;
  display: flex;
}

.tooltip_content {
  position: absolute;
  width: 303px;
  z-index: 9999;
  padding: 10px;
  color: #FFFFFF;
  border-radius: 12px ;
  text-align: start;
  white-space: pre-line;
  font-weight: 700 ;
  /* pointer-events: none; */
  animation-name: fadeInTooltip;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.hidden_tooltip {
  position: absolute;
  width: 303px;
  z-index: 9999;
  padding: 10px;
  color: #FFFFFF;
  border-radius: 12px ;
  text-align: start;
  white-space: pre-line;
  font-weight: 700 ;
  pointer-events: none;
  animation-name: fadeOutTooltip;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.tooltip_content h1,
.hidden_tooltip h1 {
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 4px;
}

.tooltip_content p,
.hidden_tooltip p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.tooltipArrow {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  rotate: 45deg;
  z-index: -1;
  background-color: inherit;
}

.tooltipClose {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  margin-right: 10px;
  stroke: #FFFFFF;
  opacity: 0.6;
}

@media (max-width: 576px) {
  .tooltipClose {
    display: block;
  }
}

@keyframes fadeInTooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutTooltip {
  0% {
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
.integrations {
    width: 100%;
    max-width: 701px;
    margin-left: 100px;
}

.integrations__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.integrations__list-item {

}

.integrations__isIntegratedBtns {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.integrations__list-item__heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #232360;
    margin-bottom: 12px;
}

.integrations__list-item__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #768396;
    margin-bottom: 24px;
    
}

.repeatTemplate__option-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0;
}

.repeatTemplate__option-el {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.repeatTemplate__option-el:hover {
    background-color: rgba(242, 238, 255, 1);
}

.repeatTemplate__option-el:hover::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 20px;
    height: 16px;
    background-color: rgba(242, 238, 255, 1);
}

.repeatTemplate__option-el:hover::after {
    content: '';
    position: absolute;
    right: 0;
    display: block;
    width: 20px;
    height: 16px;
    background-color: rgba(242, 238, 255, 1);
}

.picketOption {
    background-image: url(../../../../icons/svg/check-priority.svg);
    background-repeat: no-repeat;
    background-position: right center;
}

@media (max-width: 576px) {
    .integrations {
        width: 325px;
        margin: auto;
    }
    .integrations__list-item__description {
        max-width: 307px;
        font-size: 12px;
        line-height: 16px;
    }
}